<template>
  <div>
    <highcharts :constructorType="'mapChart'" class="hc" :options="chartOptions" ref="chart"></highcharts>
  </div>

</template>

<script>
import IndiaMap from './indiaGeo.geo.json'

export default {
  props:['data','metricType'],
  data() {
    return {
        chartData:'',
        chartOptions:''
    };
  },
  created(){
    this.chartData=''
    this.chartData = this.data
    this.init();
  },
  watch: {
    metricType: {
      handler() {
        this.init();
      },
      deep: true,
    },
  },
  methods:{
    formatNumber (num){
        if (isNaN(num)) return num
        let num1 = Number(num).toFixed(2);
        let num2 = Number(num1).toLocaleString();
        return num2
    },

    init(){
    this.chartData = this.data
    const self = this
    this.chartOptions= {
        chart: {
          map: IndiaMap,
          events: {
            click: function () {
              return false;
            },
            load: function () {
              this.update({
                mapNavigation: {
                  enabled: false
                }
              });
            }
        },
        },
        colors: ['rgba(19,64,117,0.05)'],
        title: {
          text: ''
        },
        exporting: {
          enabled: false,
        },
        subtitle: {
          text: ''
        },
        legend: {
          enabled: true,
          title:{
            text: this.metricType
          }
        },
        tooltip: {
          headerFormat:'',
          formatter() {
              if(this.point){
                let mytooltip =''
                mytooltip = this.point.name + ": ";
                mytooltip += this.point.value==-5?'Inactive State':self.formatNumber(this.point.total_value)+' ('+this.point.value+'%)'
                return mytooltip;
              }
             
          },
          shared: true,
          borderWidth: 0,
          shadow: {
            color: "#d6d6d6",
            opacity: 0.1,
            width: 10,
          },
          borderRadius: 6,
          backgroundColor: "#ffffff",
        },
        credits: {
          enabled: false,
        },
       
        mapNavigation: {
          enabled: true, 
          enableButtons: true,  
          buttonOptions: {
            verticalAlign: 'top'
          }
        },
        colorAxis: {
          min: 0,
          max:100,
          type:'gradient',
          minColor: '#F4F6F6',
          maxColor: '#065559'
        },
        series: [{
          name: 'Country',
          dataLabels: {
            enabled: false,
            style:{
              color:'#000'
            }
          },
          keys: ['hc-key', 'value', 'color','total_value'],
          nullColor:'red',
          allAreas: true,
          data: this.chartData,
        }]
      }
    }
  }
};
</script>
