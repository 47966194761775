import Loader from "@/components/Loader/Loader.vue";
import Toast from "@/components/Toast/Toast.vue";
import GenAiChatBox from "@/pages/GenAi/GenAiChatBox.vue";
import axios from "axios";
export default {
  name: "GenAiAsideBar",
  components: { Loader, GenAiChatBox, Toast },
  props: {
    // isAms: {
    //   type: String,
    //   default: ''
    // },
    isOpen: Boolean,

  },
  data() {
    return {
      chatBotLoader: false,
      isNewChat: false,
      loader: false,
      activeTab: "history",
      searchPrompt: "",
      searchHistory: "",
      allPrompts: [],
      promptQuery: "",
      promptCollapseId: [],
      isDropdownOpen: false,
      isSessionActive: false,
      filteredPrompt: null,
      isAsideBar: true,
      allSession: [],
      activeSession: { messages: [] },
      activeSessionChatId: "",
      groupChatId: "",
      showOptions: false,
      currentActiveSessionChatId: "",
      isNewSession: true,
      editSessionHeading: false,
      editGroupHeading: "",
      editPromptHeading: "",
      prevSessionHeading: "",
      updatedSessionheading: "",
      updatedPromptGroupHeading: "",
      updatedPromptHeading: "",
      deleteSessionModal: undefined,
      initialHistoryLoad: true,
      isAddPromptHeading: false,
      isGroupPromptModal: false,
      addPrompt: "",
      showPromptsOptionsMenu: "",
      addPromptGroup: "",
      storeChatId: "",
      amsFlag: '',
      draggedIndex: null,
      draggedGroupIndex: null,
      currentPath: "",
      querySent: false,
      expanded: false,
      // isChatLoaded: false,
      // loadingChats:{}
      // promptGroupOptions: [
      //   {
      //     key: "a",
      //     label: "A"
      //   },
      //   {
      //     key: "b",
      //     label: "B"
      //   },
      // ]
    };
  },
  mounted() {
    this.isNewSession=true;
    this.storeChatId = localStorage.getItem("chatId");
    if (this.storeChatId) {
      this.getChats(this.storeChatId)
    }
    this.getChats();
    this.getPrompts();
  },
  created() {
    window.addEventListener("click", this.toggleOptionMenu);
  },
  beforeDestroy() {
    window.removeEventListener("click", this.toggleOptionMenu);
  },
  computed: {
    filteredGroupPrompts() {
      if (!this.searchPrompt.trim()) {
        return this.allPrompts;
      }
      const matchedGroups = this.allPrompts?.map(group => {
        const groupMatches = group.group_heading.trim().toLowerCase().includes(this.searchPrompt.trim().toLowerCase());

        const filteredPrompts = group.prompts?.filter(prompt =>
          prompt.prompt.trim().toLowerCase().includes(this.searchPrompt.trim().toLowerCase())
        ) || [];
        const promptsToShow = groupMatches ? group.prompts : filteredPrompts;
        return promptsToShow?.length > 0 ? {
          ...group,
          prompts: promptsToShow
        } : null;
      }).filter(Boolean);


      this.promptCollapseId = matchedGroups.map(group => group.group_id);
      if (this.promptCollapseId) {
        this.isDropdownOpen = true;
      }
      return matchedGroups;
    },

    filteredData() {
      if (!this.searchHistory) return this.allSession;

      const result = {};

      for (const [groupName, items] of Object.entries(this.allSession)) {

        const filteredItems = items.filter((item) =>
          item.chat_heading.trim().toLowerCase().includes(this.searchHistory.trim().toLowerCase())
        );
        if (filteredItems.length > 0) {
          result[groupName] = filteredItems;
        }
      }

      return result;
    },

  },
  methods: {
    expandSearch() {
      this.expanded = true;
    },
    shrinkSearch() {
      if (!this.searchQuery) {
        this.expanded = false;
      }
    },
    onDragStart(groupIndex, promptIndex) {
      this.draggedGroupIndex = groupIndex;
      this.draggedPromptIndex = promptIndex;
    },
    onDrop(groupIndex, promptIndex) {
      if (!Array.isArray(this.allPrompts)) {
        console.error("Invalid Prompts array.");
        return;
      }

      if (this.draggedGroupIndex === null || this.draggedPromptIndex === null || this.draggedGroupIndex === undefined || this.draggedPromptIndex === undefined) {
        console.error("Invalid dragged indices.");
        return;
      }

      const draggedItem = this.allPrompts[this.draggedGroupIndex].prompts[this.draggedPromptIndex];

      this.allPrompts[this.draggedGroupIndex].prompts.splice(this.draggedPromptIndex, 1);
      if (!Array.isArray(this.allPrompts[groupIndex].prompts)) {
        this.allPrompts[groupIndex].prompts = [];
      }

      const insertIndex = promptIndex !== null && promptIndex !== undefined ? promptIndex : this.allPrompts[groupIndex].prompts.length;
      this.allPrompts[groupIndex].prompts.splice(insertIndex, 0, draggedItem);

      const url = "/api/v1/chatbot/move/prompt";
      const payload = {
        prompt_id: draggedItem.prompt_id,
        old_group_id: this.allPrompts[this.draggedGroupIndex].group_id,
        new_group_id: this.allPrompts[groupIndex].group_id,
        index: promptIndex
      }

      axios.post(url, payload).than((res) => {
        console.log(res.data);
        this.draggedGroupIndex = null;
        this.draggedPromptIndex = null;
      }).catch((err) => {
        console.error(err);
      })
    },
    // handleIsAms(val) {
    //   this.amsFlag = val;
    //   console.log(val, "check data");
    //   // if (this.amsFlag == 'isams') {
    //   //   this.isNewSession = false;
    //   //   this.getChats(this.storeChatId);
    //   // }
    // },
    switchTab(currentTab) {
      this.activeTab = currentTab;
      this.searchPrompt = "";
      this.searchHistory = "";
    },
    handleSessionUpdate(newValue) {
      this.isNewSession = newValue;
    },
    toggleMaximize(value) {
      this.isAsideBar = !value;
    },
    toggleOptionMenu(sessionChatId) {
      this.showOptions = this.showOptions === sessionChatId ? false : sessionChatId;
      this.addPrompt = "";
    },
    togglePromptOptionsMenu(prompt_id) {
      if (this.showPromptsOptionsMenu === prompt_id) {
        this.showPromptsOptionsMenu = null;
      } else {
        this.showPromptsOptionsMenu = prompt_id;
      }
    },
    toggleEditHeading(sessionChatId, chatHeading) {
      if (sessionChatId && chatHeading) {
        this.editSessionHeading = sessionChatId;
        this.prevSessionHeading = chatHeading;
        this.$nextTick(() => {
          this.$refs.updateHeading.focus();
        });
      } else {
        this.editSessionHeading = false;
        this.prevSessionHeading = "";
        // this.getChats();
      }

      this.toggleOptionMenu();
    },
    togglePromptGroupModal() {
      this.addPromptGroup = "";
      this.isGroupPromptModal = !this.isGroupPromptModal;
    },
    startNewChat() {
      this.isNewChat = false;
      this.activeSessionChatId = "";
      if (this.isNewSession && !this.activeSession.messages.length) {
        return;
      }
      this.isNewSession = true;
      this.activeSession.messages = [];
    },
    loadActiveSession(activeSessionChatId, setLoader = true) {
      if (
        // this.currentActiveSessionChatId === activeSessionChatId ||
        this.editSessionHeading === activeSessionChatId
      ) {
        return;
      }
      this.isNewChat = true;
      this.currentActiveSessionChatId = activeSessionChatId;
      if (setLoader) {
        this.chatBotLoader = true;
      }
      // this.isChatLoaded = !!this.loadingChats[activeSessionChatId];
      this.getChats(activeSessionChatId);
      this.isNewSession = false;
    },
    loadActiveGroupPrompt(groupId) {
      this.editGroupHeading = groupId;
    },
    loadActivePrompt(promptId) {
      this.editPromptHeading = promptId;
    },
    sendPrompt(prompt) {
      this.promptQuery = prompt;
    },
    togglePromptCollapse(groupId) {
      this.addPrompt = "";
      this.isDropdownOpen = true;

      if (!Array.isArray(this.promptCollapseId)) {
        this.promptCollapseId = [];
      }
      if (this.promptCollapseId.includes(groupId)) {
        this.promptCollapseId = this.promptCollapseId.filter(id => id !== groupId);
      } else {
        this.promptCollapseId.push(groupId);
      }
    },
    // togglePromptCollapse(groupId) {
    //   // if (this.promptCollapseId !== groupId) {
    //   //   this.groupPrompts = [];
    //   //   this.addGroupPrompt = "";
    //   //   // this.getPrompts(groupId);
    //   //   this.isDropdownOpen = true;
    //   //   this.promptCollapseId = groupId;
    //   // } else {
    //   //   this.isDropdownOpen = false;
    //   //   this.promptCollapseId = null;
    //   // }
    //   this.groupPrompts = [];
    //   this.addGroupPrompt="";
    //   // this.getPrompts(groupId);
    //   this.isDropdownOpen = true;
    //   // this.promptCollapseId = this.promptCollapseId === groupId ? null : groupId;
    //   if (this.promptCollapseId.includes(groupId)) {
    //     this.promptCollapseId = this.promptCollapseId.filter(id => id !== groupId);
    //   } else {
    //     this.promptCollapseId.push(groupId);
    //   }
    // },
    toggleAddPromptHeading(groupId) {
      this.addPrompt = "";
      this.isAddPromptHeading = this.isAddPromptHeading === groupId ? null : groupId;
    },
    //--- updateing chat heading---------
    updateHeading(chatId, updatedValue, prevValue) {
      if (updatedValue.trim() === prevValue.trim()) {
        this.$toast.error("Already upto date!", {
          position: "top-center",
          timeout: 2000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
        this.showOptions = false;
        this.toggleEditHeading();
        return;
      }
      const payload = { chat_id: chatId, new_heading: updatedValue.trim() };
      const url = "/api/v1/chatbot/update/chat_heading";
      this.loader = true;
      axios
        .post(url, payload)
        .then((res) => {
          this.loader = false;
          this.getChats();
          this.$toast.success(res.data.summary, {
            position: "top-center",
            timeout: 2000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
          this.showOptions = false;
          this.toggleEditHeading();
        })
        .catch((error) => {
          this.loader = false;
          this.toggleOptionMenu(chatId);
          console.error(error);
          this.$toast.error(error, {
            position: "top-center",
            timeout: 2000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: true,
          });
          this.showOptions = false;
          this.toggleEditHeading();
        });
    },

    // --deleting chat-----------
    deleteSession(chat_id) {
      const payload = { chat_id: chat_id };
      if (!confirm("Are you sure, to delete the session?")) {
        return;
      }
      const url = "/api/v1/chatbot/delete/chat";
      this.loader = true;
      axios
        .post(url, payload)
        .then((res) => {
          this.loader = false;
          this.querySent=true;
          if (chat_id == this.storeChatId) {
            localStorage.removeItem("chatId")
          }
          this.getChats();
          this.toggleOptionMenu(chat_id);
          this.startNewChat();
          this.$toast.success(res.data.summary, {
            position: "top-center",
            timeout: 2000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
        })
        .catch((error) => {
          this.loader = false;
          this.toggleOptionMenu(chat_id);
          console.error(error);
          this.$toast.error(error, {
            position: "top-center",
            timeout: 2000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: true,
          });
        });
    },
    handleAddPrompt(groupData) {
      const url = "/api/v1/chatbot/add/prompt";

      if (this.addPrompt.trim() == "") {
        this.$toast.error("Prompt cannot be empty", {
          position: "top-center",
          timeout: 2000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
        return false;
      }

      const payload = {
        group_heading: groupData.group_heading,
        group_id: groupData.group_id,
        prompt: this.addPrompt.trim()
      }

      axios.post(url, payload).then((res) => {
        this.$toast.success(res.data.summary, {
          position: "top-center",
          timeout: 2000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
        this.getPrompts(groupData.group_id);
        this.isAddPromptHeading = "";
        this.showOptions = "";
      }).catch(err => {
        this.$toast.danger(err, {
          position: "top-center",
          timeout: 2000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: true,
        });
        this.getPrompts(groupData.group_id);
        this.isAddPromptHeading = "";
        this.showOptions = "";
      })
    },
    handleAddPromptGroup() {
      const url = "/api/v1/chatbot/add/prompt_group";

      if (this.addPromptGroup.trim() == "") {
        this.$toast.error("Field cannot be empty", {
          position: "top-center",
          timeout: 2000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
        return false;
      }

      const payload = {
        group_heading: this.addPromptGroup
      }
      this.loader = true;

      axios.post(url, payload).then((res) => {
        this.getPrompts();
        this.$toast.success(res.data.summary, {
          position: "top-center",
          timeout: 2000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
        this.isGroupPromptModal = false;
        this.addPromptGroup = "";
      }).catch((err) => {
        this.loader = false;
        this.$toast.error(err, {
          position: "top-center",
          timeout: 2000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: true,
        });
        this.isGroupPromptModal = false;
        this.addPromptGroup = "";
      })
    },
    handleUpdatePromptGroupHeading(group_id, newHeading) {
      if (newHeading.trim() == "") {
        this.$toast.error("Heading cannot be empty", {
          position: "top-center",
          timeout: 2000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
        return false;
      }
      const payload = {
        group_id: group_id,
        new_heading: newHeading.trim()
      };
      const url = "/api/v1/chatbot/update/prompt_group_heading";
      this.loader = true;
      axios
        .post(url, payload)
        .then((res) => {
          this.loader = false;
          this.getPrompts();
          this.$toast.success(res.data.summary, {
            position: "top-center",
            timeout: 2000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
          this.editGroupHeading = "";
          this.showOptions = false;
        })
        .catch((err) => {
          this.loader = false;
          this.$toast.error(err, {
            position: "top-center",
            timeout: 2000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: true,
          });
          this.showOptions = false;
          this.editGroupHeading = "";
        });
    },
    deletePromptGroup(group_id) {
      if (!confirm("Are you sure, to delete the prompt's group?")) {
        return;
      }
      const url = "/api/v1/chatbot/delete/prompt_group";
      const payload = {
        group_id: group_id
      }

      axios.post(url, payload).then((res) => {
        this.$toast.success(res.data.summary, {
          position: "top-center",
          timeout: 2000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
        this.getPrompts();
      }).catch(err => {
        this.$toast.error(err, {
          position: "top-center",
          timeout: 2000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: true,
        });
        this.getPrompts();
      })
    },
    handleUpdatedPromptHeading(prompt_id, newPrompt) {
      if (newPrompt.trim() == "") {
        this.$toast.error("Heading cannot be empty", {
          position: "top-center",
          timeout: 2000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
        return false;
      }
      const payload = {
        prompt_id: prompt_id,
        new_prompt: newPrompt.trim()
      };
      const url = "/api/v1/chatbot/update/prompt";
      this.loader = true;
      axios
        .post(url, payload)
        .then((res) => {
          this.loader = false;
          this.getPrompts();
          this.$toast.success(res.data.summary, {
            position: "top-center",
            timeout: 2000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
          this.editPromptHeading = "";
          this.showPromptsOptionsMenu = null;
        })
        .catch((err) => {
          this.loader = false;
          this.$toast.error(err, {
            position: "top-center",
            timeout: 2000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: true,
          });
          this.showPromptsOptionsMenu = null;
          this.editPromptHeading = "";
        });
    },
    handleDeletePrompt(promptGroup, prompt_id) {
      if (!confirm("Are you sure, to delete the prompt?")) {
        return;
      }
      const url = "/api/v1/chatbot/delete/prompt";
      const payload = {
        prompt_id: prompt_id,
        group_id: promptGroup.group_id
      }

      axios.post(url, payload).then((res) => {
        this.$toast.success(res.data.summary, {
          position: "top-center",
          timeout: 2000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
        this.showPromptsOptionsMenu = null;
        this.getPrompts();
      }).catch(err => {
        this.$toast.error(err, {
          position: "top-center",
          timeout: 2000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: true,
        });
        this.showPromptsOptionsMenu = null;
        this.getPrompts();
      })
    },
    //--------------getting user's Chats------------
    getChats(chat_id = null, page = 1) {
      const payload = {};
      if (chat_id) {
        payload.chat_id = chat_id;
      }
      this.isNewSession = false;

      payload.page = page;
      let url = "/api/v1/chatbot/chats";
      if (this.initialHistoryLoad) {
        this.chatBotLoader = false;
        this.initialHistoryLoad = false;
      }
      axios
        .post(url, payload)
        .then((res) => {
          if (chat_id) {
            this.activeSession = res.data.data.chats[0] || { messages: [] };
            this.activeSessionChatId = chat_id;
            this.querySent=true;
            this.chatBotLoader = false;
          } else {
            this.allSession = res.data.data.grouped_chat_data;
            this.chatBotLoader = false;
            if (this.allSession.last_week?.length == 0) {
              this.startNewChat();
              return;
            }
            this.activeSessionChatId = this.currentActiveSessionChatId;
            // this.activeSession =
            //   this.allSession.last_week.find((session) => session.chat_id === this.activeSessionChatId) || { messages: [] };
          }
        })
        .catch((err) => {
          this.chatBotLoader = false;
          this.activeSession = { messages: [] };
          console.log(err);
        });
    },

    //--------------getting user's prompts------------
    getPrompts() {
      const payload = {};
      this.loader = true;
      let url = "/api/v1/chatbot/prompts";
      axios
        .post(url, payload)
        .then((res) => {
          this.allPrompts = res.data.data.prompts;
          this.promptCollapseId.push(this.allPrompts[0].group_id);
          this.isDropdownOpen = true;
          // this.promptGroupOptions=res.data.data.prompts;
          // }
          this.loader = false;
        })
        .catch((err) => {
          this.loader = false;
          console.log(err);
        });
    },
  },
};


