import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import VueHighcharts from 'highcharts-vue'
import HighchartsVue from 'highcharts-vue';
import Highcharts from  "highcharts";
import Maps from "highcharts/modules/map";
import "./assets/css/tailwind.css";
import Multiselect from 'vue-multiselect'
import store from './store/index'

import Toast from 'vue-toastification'
import "vue-toastification/dist/index.css";
// import './registerServiceWorker'
// import HeatmapModule from "highcharts/modules/heatmap";

// HeatmapModule(Highcharts); 

Vue.use(VueHighcharts, HighchartsVue, { Highcharts })
library.add(fas,fab,far)
const options = {
  // You can set your default options here
};


Vue.use(Toast, options);

Vue.component('font-awesome-icon', FontAwesomeIcon)

Maps(Highcharts);

Vue.component('DateRangePicker',DateRangePicker);

Vue.component('multiselect', Multiselect);

Vue.config.productionTip = false

Highcharts.setOptions({
  lang: {
      decimalPoint: '.',
      thousandsSep: ','
  }
});

new Vue({
  store,
  router,
  render: h => h(App)
}).$mount('#app')
