import axios from "axios";
import moment from "moment";
import Loader from "@/components/Loader/Loader.vue";
import Treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import { watch } from "vue";

export default {
    name: "Flipkart Sleepwell",
    components: {
        Loader,
        Treeselect
    },
    data() {
        return {
            dataLoader: false,
            campLoader: false,
            isFilter: false,
            isAsinFilter: false,
            filterBy: "",
            dateRange: {
                startDate: moment().subtract(1, "week").format("YYYY-MM-DD"),
                endDate: moment().format("YYYY-MM-DD"),
            },
            intervalsList: [
                {
                    key: "daily",
                    label: "Day",
                },
                {
                    key: "weekly",
                    label: "Week",
                },
                {
                    key: "monthly",
                    label: "Month",
                },
                {
                    key: "yearly",
                    label: "Year",
                },
            ],
            selectedInterval: {
                key: "daily",
                label: "Day",
            },
            selectedCampaignTypeData: "pla",
            campaignOptions: [],
            selectedNumericCampaignValue: "views",
            numericFiltersFields: [],
            selectedNumericMatricsValue: "greater than",
            numericFilterMatrics: [],
            matricsInputValue: "",
            selectedStringCampaignValue: "campaign_name",
            stringFiltersFields: [],
            selectedStringMatricsValue: "contains",
            stringFilterMatrics: [],
            StringMatricsInputValue: "",
            selectedAsinStringMatricsValue: "contains",
            selectedAsinStringCampaignValue: "FSN",
            asinMatricsStringInputValue: "",
            asinMetricFields:[],
            tableHeader: [],
            tableData: [],
            storeNumericFilterValue: [],
            storeStringFilterValue: [],
            selectedTableColumn: [],
            asinTable: {
                data: [],
                header: [],
                numberMetric: [],
                numberFields: [],
            },
            allAsinCategory: [],
            asinCategoryValue: null,
            asinFilterBy: "",
            selectedAsinTableColumn: [],
            treeSelectData: [],
            selectedCategory: {},
            isDefaultSelectedCategory: false,
            selectedAsinNumericalCampaignValue: "views",
            selectedAsinNumericalMatricsValue: "greater than",
            asinMatricsNumericalInputValue: "",
            storeAsinFilterValue: [],
            storeAsinMetricsValue: [],
        }
    },
    mounted() {
        this.isDefaultSelectedCategory = true;
        this.getTargetKurlonReport();
        this.getAsinReport();


        this.$watch('allAsinCategory', (newVal) => {
            if (newVal && Object.keys(newVal).length) {
                this.treeSelectData = this.transformToTreeSelect(newVal);
            }
        }, { immediate: true });
    },
    methods: {
        formatDateRange() {
            this.dateRange.startDate = moment(this.dateRange.startDate).format(
                "YYYY-MM-DD"
            );
            this.dateRange.endDate = moment(this.dateRange.endDate).format(
                "YYYY-MM-DD"
            );
        },
        formatDates() {
            this.formatDateRange();
            this.getTargetKurlonReport();
            this.getAsinReport();
        },
        setInterval(selectedInterval) {
            this.selectedInterval = selectedInterval;
            this.getTargetKurlonReport();
            this.getAsinReport();
        },
        handleCampaignType() {
            this.getTargetKurlonReport();
        },
        removeFilterValue(storeFilterValue, data) {
            const index = storeFilterValue
                .flat()
                .findIndex(
                    (item) =>
                        item.field === data.field &&
                        item.operation === data.operation &&
                        item.value === data.value
                );

            if (index !== -1) {
                storeFilterValue.splice(index, 1);
            }
        },
        RemoveBadgeValue(data) {
            this.removeFilterValue(this.storeNumericFilterValue, data);

            for (let i = 0; i < this.storeStringFilterValue.length; i++) {
                const itemArray = this.storeStringFilterValue[i];
                const matchIndex = itemArray.findIndex(
                    (item) =>
                        item.field === data.field &&
                        item.operation === data.operation &&
                        item.value === data.value
                );

                if (matchIndex !== -1) {
                    this.storeStringFilterValue.splice(i, 1);
                    break;
                }
            }
            this.removeFilterValue(this.storeAsinFilterValue, data);
            this.removeFilterValue(this.storeAsinMetricsValue, data);
            this.getTargetKurlonReport();
            this.getAsinReport();
        },
        RemoveAllBadgeValue() {
            this.storeNumericFilterValue = [];
            this.storeStringFilterValue = [];
            this.getTargetKurlonReport();
        },
        getTargetKurlonReport() {
            this.dataLoader = true;
            const url = "/api/fk/targated/report";
            const payload = {
                // start_date: this.dateRange.startDate,
                // end_date: this.dateRange.endDate,
                "start_date": "2024-10-01",
                "end_date": "2024-10-07",
                report_type: this.selectedInterval.key,
                campaign_type: this.selectedCampaignTypeData,
                numarical_filters: this.storeNumericFilterValue.flat(),
                string_filters: this.storeStringFilterValue.flat()
            }

            axios.post(url, payload).then((res) => {
                this.tableHeader = res.data.data.headers;
                this.selectedTableColumn = this.tableHeader;
                this.tableData = res.data.data.campaign_data;
                this.campaignOptions = res.data.data.ava_campaign_type;
                this.numericFiltersFields = res.data.data.numarical_filters.fields;
                this.numericFilterMatrics = res.data.data.numarical_filters.matrics;
                this.stringFiltersFields = res.data.data.string_filters.fields;
                this.stringFilterMatrics = res.data.data.string_filters.matrics;
                this.$nextTick(() => {
                    this.initDataTable('#KurlonTargetDataTable', this.tableHeader, this.tableData);
                });
                this.dataLoader = false;
            }).catch(err => {
                console.error(err);
                this.dataLoader = false;
            })
        },

        getAsinReport() {
            this.campLoader = true;
            this.allAsinCategory = '';


            const url = "/api/amazon/targeted/fsnreport";
            const payload = {
                // start_date: this.dateRange.startDate,
                // end_date: this.dateRange.endDate,
                "start_date": "2024-10-01",
                "end_date": "2024-10-07",
                interval: this.selectedInterval.key,
                category_filters: this.selectedCategory,
                numarical_filters: this.storeAsinFilterValue.flat(),
            }

            axios.post(url, payload).then((res) => {
                this.asinTable.data = res.data.data.data;
                this.asinTable.header = res.data.data.headers;
                this.selectedAsinTableColumn = this.asinTable.header;
                this.allAsinCategory = res.data.data.category;
                this.asinTable.numberMetric = res.data.data.metric;
                this.asinTable.numberFields = res.data.data.fileds;
                this.asinMetricFields=res.data.data.fsn_string_metric;
                this.treeSelectData = this.transformToTreeSelect(res.data.data.category);
                if (this.treeSelectData && this.treeSelectData.length > 0 && this.isDefaultSelectedCategory) {
                    this.asinCategoryValue = [this.treeSelectData[0].id];
                    this.handleTreeSelect(this.treeSelectData[0]);
                }
                this.$nextTick(() => {
                    this.initDataTable('#asinDataTable', this.asinTable.header, this.asinTable.data);
                });
                this.isDefaultSelectedCategory = false;
                this.campLoader = false;
            }).catch(err => {
                console.error(err);
                this.campLoader = false;

            })
        },
        initDataTable(id, header, data) {
            const column = header.map((header) => ({
                title: header.value,
                data: header.key,
            }));

            if ($.fn.DataTable.isDataTable(id)) {
                $(id).DataTable().clear().destroy();
            }
            $(id).empty();


            $(id).DataTable({
                data: data,
                columns: column,
                info: true,
                paging: true,
                searching: true,
                ordering: true,
                initComplete: function () {
                    $(id).wrap(
                        "<div style='overflow:auto; width:100%;position:relative;'></div>"
                    );
                },
                language: {
                    search: "",
                    searchPlaceholder: "Search...",
                    paginate: {
                        first: "",
                        previous: "<",
                        next: ">",
                        last: "",
                    },
                    lengthMenu: "<h5>Show entries</h5> _MENU_",
                },
            });
            const searchIcon = require("@/assets/Icons_SVG/Search.svg");
            $(`${id}_filter > label`).append(`
              <div class='search_icon'>
                <img src='${searchIcon}' alt='Search' />
              </div>
            `);
            function adjustCloseIconPosition() {
                const screenWidth = window.innerWidth;

                if (screenWidth >= 1400) {
                    $(id).css({
                        width: "1750px",
                    });
                    $(`${id} .sorting_1`).css({
                        width: "80px",
                    });
                } else {
                    $(id).css({
                        width: "1300px",
                    });
                    $(`${id} .sorting_1`).css({
                        width: "80px",
                    });
                }
            }

            adjustCloseIconPosition();
            $(window).resize(adjustCloseIconPosition);
        },
        transformToTreeSelect(data) {
            return Object.keys(data).map((category, idx) => {
                return {
                    id: idx + category,
                    label: category,
                    parent: category,
                    children: Object.keys(data[category]).map((type, idx) => {
                        return {
                            id: idx + category + type,
                            parentCat: category,
                            label: type,
                            children: data[category][type].map((size, idx) => ({
                                id: idx + category + type + size,
                                cat: category,
                                sub: type,
                                label: size,
                            })),
                        };
                    }),
                };
            });
        },
        handleDownloadTable() {
            let url = "/api/data/downloadtoxlsx";

            const payload = {
                report_title: "sleepwellReport",
                headers: this.selectedTableColumn,
                data: this.tableData.map((data) => {
                    const filteredData = {};
                    this.selectedTableColumn.forEach((column) => {
                        if (data.hasOwnProperty(column.key)) {
                            filteredData[column.key] = data[column.key];
                        }
                    });
                    return filteredData;
                })
            };

            axios.post(url, payload).then((res) => {
                window.location = res.data.url;
            }).catch((err) => {
                console.error(err);

            })
        },
        handleDownloadAsinTable() {
            let url = "/api/data/downloadtoxlsx";

            const payload = {
                report_title: "asinReport",
                headers: this.selectedAsinTableColumn,
                data: this.asinTable.data.map((data) => {
                    const filteredData = {};
                    this.selectedAsinTableColumn.forEach((column) => {
                        if (data.hasOwnProperty(column.key)) {
                            filteredData[column.key] = data[column.key];
                        }
                    });
                    return filteredData;
                })
            }

            axios.post(url, payload).then((res) => {
                window.location = res.data.url;
            }).catch((err) => {
                console.error(err);

            })
        },
        handleTableColumn() {
            const dateColumn = this.tableHeader.find(item => item.value === 'Date');

            if (!this.selectedTableColumn.some(col => col.value === 'Date')) {
                this.selectedTableColumn.unshift(dateColumn);
            }
            this.$nextTick(() => {
                this.initDataTable('#KurlonTargetDataTable', this.selectedTableColumn, this.tableData);
            })
        },
        handleAsinTableColumn() {
            const dateColumn = this.asinTable.header.find(item => item.key === 'date');

            if (!this.selectedAsinTableColumn.some(col => col.key === 'date')) {
                this.selectedAsinTableColumn.unshift(dateColumn);
            }
            this.$nextTick(() => {
                this.initDataTable('#asinDataTable', this.selectedAsinTableColumn, this.asinTable.data);
            });
        },
        handleIsAsinFilter() {
            this.isAsinFilter = true;
        },
        closeAsinFilter() {
            this.isAsinFilter = false;
        },
        handleIsFilter() {
            this.isFilter = true;
        },
        handleFilterModal(data, storeFilterValue, selectedMatricsValueKey, matricsInputValueKey) {
            if (data.length <= 0) {
                return false;
            }

            let existingValue = storeFilterValue.find(
                (item) => item[0].field === data && item[0].operation === this[selectedMatricsValueKey]
            );

            if (existingValue) {
                this[matricsInputValueKey] = existingValue[0].value;
            } else {
                this[matricsInputValueKey] = "";
            }
        },
        handleNumericFilterModal(data) {
            this.handleFilterModal(data, this.storeNumericFilterValue, 'selectedNumericMatricsValue', 'matricsInputValue');
        },
        handleStringFilterModal(data) {
            this.handleFilterModal(data, this.storeStringFilterValue, 'selectedStringMatricsValue', 'StringMatricsInputValue');
        },
        handleMatricsFilterModal(data) {
            let existingValue = this.storeNumericFilterValue.find(
                (item) => item[0].field === this.selectedNumericCampaignValue && item[0].operation === data)
            if (existingValue) {
                this.matricsInputValue = existingValue[0].value;
            } else {
                this.matricsInputValue = ""
            }
        },
        handleAsinStringFilterModal(data) {
            this.handleFilterModal(
              data,
              this.storeAsinFilterValue,
              'selectedAsinStringMatricsValue',
              'asinMatricsStringInputValue'
            );
          },
        handleStringMatricsFilterModal(data) {
            let existingValue = this.storeStringFilterValue.find(
                (item) => item[0].field === this.selectedStringCampaignValue && item[0].operation === data)
            if (existingValue) {
                this.StringMatricsInputValue = existingValue[0].value;
            } else {
                this.StringMatricsInputValue = ""
            }
        },
        handleAsinFilterModal(data) {
            this.handleFilterModal(
                data,
                this.storeAsinFilterValue,
                'selectedAsinNumericalMatricsValue',
                'asinMatricsNumericalInputValue'
            );
        },
        ResetAsinFilter() {
            this.storeAsinFilterValue = [];
            this.storeAsinMetricsValue = [];
            this.getAsinReport();
        },
        handleAsinMatricsFilterModal(data) {
            let existingValue = this.storeAsinFilterValue.find(
                (item) => item[0].field === this.selectedAsinNumericalCampaignValue && item[0].operation === data)
            if (existingValue) {
                this.asinMatricsNumericalInputValue = existingValue[0].value;
            } else {
                this.asinMatricsNumericalInputValue = ""
            }
        },
        handleAsinStringMatricsFilterModal(data) {
            let existingValue = this.storeAsinMetricsValue.find(
              (item) => item[0].field === this.selectedAsinStringCampaignValue && item[0].operation === data)
            if (existingValue) {
              this.asinMatricsStringInputValue = existingValue[0].value;
            } else {
              this.asinMatricsStringInputValue = ""
            }
          },
        clearFilter() {
            this.isFilter = false;
            this.matricsInputValue = "";
            this.StringMatricsInputValue = "";
        },
        handlePostFilters({
            selectedCampaignValue,
            selectedMatricsValue,
            matricsInputValue,
            storeFilterValue,
            getTableDataFunction
        }) {
            let existingFilter = storeFilterValue.find(
                (item) => item[0].field === selectedCampaignValue && item[0].operation === selectedMatricsValue
            );

            if (existingFilter) {
                existingFilter[0].value = isNaN(matricsInputValue) ? matricsInputValue : parseInt(matricsInputValue);
            } else {
                storeFilterValue.push([
                    {
                        operation: selectedMatricsValue,
                        field: selectedCampaignValue,
                        value: isNaN(matricsInputValue) ? matricsInputValue : parseInt(matricsInputValue)
                    }
                ]);
            }

            if (typeof getTableDataFunction === "function") {
                getTableDataFunction();
            }
        },
        handlePostNumericFilters() {
            this.handlePostFilters({
                selectedCampaignValue: this.selectedNumericCampaignValue,
                selectedMatricsValue: this.selectedNumericMatricsValue,
                matricsInputValue: this.matricsInputValue,
                storeFilterValue: this.storeNumericFilterValue,
                getTableDataFunction: this.getTargetKurlonReport
            });
            this.isFilter = false;
        },
        handlePostStringFilters() {
            this.handlePostFilters({
                selectedCampaignValue: this.selectedStringCampaignValue,
                selectedMatricsValue: this.selectedStringMatricsValue,
                matricsInputValue: this.StringMatricsInputValue,
                storeFilterValue: this.storeStringFilterValue,
                getTableDataFunction: this.getTargetKurlonReport
            });
            this.isFilter = false;
        },
        postAsinFilters() {
            this.handlePostFilters({
                selectedCampaignValue: this.selectedAsinNumericalCampaignValue,
                selectedMatricsValue: this.selectedAsinNumericalMatricsValue,
                matricsInputValue: this.asinMatricsNumericalInputValue,
                storeFilterValue: this.storeAsinFilterValue,
                getTableDataFunction: this.getAsinReport
            });
            this.isAsinFilter = false;
        },
        postAsinMetricsFilters() {
            this.storeAsinMetricsValue.push([
              {
                operation: this.selectedAsinStringMatricsValue,
                field: this.selectedAsinStringCampaignValue,
                value: this.asinMatricsStringInputValue
              }
            ])
            this.getAsinReport();
            this.isAsinFilter = false;
          },
        handleTreeSelect(node) {
            if (this.selectedCategory) {
                if (node.parent) {
                    this.selectedCategory[node.label] = {}
                }
                else if (node.parentCat) {
                    if (this.selectedCategory[node.parentCat]) {
                        this.selectedCategory[node.parentCat][node.label] = []
                    }
                    else {
                        this.selectedCategory[node.parentCat] = {}
                        this.selectedCategory[node.parentCat][node.label] = []
                    }
                }
                else if (node.sub) {
                    if (!this.selectedCategory[node.cat]) {
                        this.selectedCategory[node.cat] = {};
                    }

                    if (!this.selectedCategory[node.cat][node.sub]) {
                        this.selectedCategory[node.cat][node.sub] = [];
                    }

                    this.selectedCategory[node.cat][node.sub].push(node.label);
                }
                this.getAsinReport();
            }
        },
        handleTreeDeSelect(node) {
            if (this.selectedCategory) {
                if (node.parent) {
                    delete this.selectedCategory[node.label];
                }
                else if (node.parentCat) {
                    if (this.selectedCategory[node.parentCat]) {
                        delete this.selectedCategory[node.parentCat][node.label];

                        if (Object.keys(this.selectedCategory[node.parentCat]).length === 0) {
                            delete this.selectedCategory[node.parentCat];
                        }
                    }
                }
                else if (node.sub) {
                    if (this.selectedCategory[node.cat] && this.selectedCategory[node.cat][node.sub]) {
                        const index = this.selectedCategory[node.cat][node.sub].indexOf(node.label);
                        if (index > -1) {
                            this.selectedCategory[node.cat][node.sub].splice(index, 1);
                        }

                        if (this.selectedCategory[node.cat][node.sub].length === 0) {
                            delete this.selectedCategory[node.cat][node.sub];
                        }

                        if (Object.keys(this.selectedCategory[node.cat]).length === 0) {
                            delete this.selectedCategory[node.cat];
                        }
                    }
                }

                this.getAsinReport();
            }
        },
        handleClear(newValue) {
            if (!newValue || newValue.length === 0) {
                this.selectedCategory = {};
                this.getAsinReport();
            }
        }
    },
}