import moment from 'moment';
import axios from 'axios';
import Loader from "@/components/Loader/Loader.vue";
import lineChart from '@/components/charts/lineChart.vue';
import funnelChart from '@/components/charts/funnelChart.vue';
import pieChart from '@/components/charts/pieChart.vue';
import dualAxisChart from '@/components/charts/dualAxisChart.vue'

export default {
    name: "FlipkartSummary",
    components: {
        Loader,
        lineChart,
        funnelChart,
        pieChart,
        dualAxisChart,
    },
    data() {
        return {
            loader: false,
            dateRange: {
                startDate: moment("2024-05-01").format("YYYY-MM-DD"),
                endDate: moment("2024-05-05").format("YYYY-MM-DD")
                // endDate: moment().subtract(1, 'week').format("YYYY-MM-DD")
            },
            maxRange: moment().format("YYYY-MM-DD"),
            platformsList: ['Flipkart', 'Supermart'],
            selectedPlatform: 'Flipkart',
            reportsList: ['PLA', 'PCA'],
            selectedReport: 'PLA',
            accountsList: ['AA_Account_1'],
            selectedAccount: 'AA_Account_1',
            metricData: [],
            processedMetricData: [],
            metricChartData: [],
            metricsList: [],
            selectedMetric1: "",
            selectedMetric2: "",
            processedMetricChart: {},
            dynamicLevelTableData: {},
            dailyTableData: {},
            selectedTab: '',
            level: '',
            imgModal: null,
            previewImg: null,
            currentClient: JSON.parse(localStorage.userData).clients.current
        }
    },
    mounted() {
        this.initializeModal();
        if(this.currentClient == "Demo Account"){
            this.dateRange.startDate = moment().subtract(1,'month').format("YYYY-MM-DD")
            this.dateRange.endDate = moment().format("YYYY-MM-DD")
            this.getAccountNameList();
        }
        else{
            if(this.selectedAccount) {
                this.dataAPIs();
            }
        }
        
    },
    methods: {
        getAccountNameList(){
            const url ="/api/flipkart/default_account_filter"
            axios.get(url).then(res=>{
                this.accountsList   = [res.data.data].map(data=>data.account_id)
                this.selectedAccount = this.accountsList[0];
                this.dataAPIs();
            })
        },
        initializeModal() {
            const $imageModal = document.getElementById("image-modal");
            const imageModalOptions = {
                placement: "center",
                backdrop: "dynamic",
                backdropClasses: "bg-gray-900 bg-opacity-50 fixed inset-0 z-40",
                closable: true,
                onHide: () => {
                    this.previewImg = null
                }
            };

            //assign modal
            this.imgModal = new Modal($imageModal, imageModalOptions);
        },
        formatDateRange() {
            this.dateRange.startDate = moment(this.dateRange.startDate).format("YYYY-MM-DD")
            this.dateRange.endDate = moment(this.dateRange.endDate).format("YYYY-MM-DD")
            this.dataAPIs();
        },

        dataAPIs() {
            this.getTopBoxesBulkData();
        },

        toggleTab(tab) {
            this.selectedTab = tab
        },
        openModal(imgSrc) {
            this.previewImg = imgSrc;
            this.imgModal.show()
        },
        navigateBack(currentLevel) {
            if (currentLevel === 'adgroup') {
                this.getTopBoxesBulkData();
                localStorage.removeItem("campaign_id");
            } else if (currentLevel === 'fsn') {
                this.getTopBoxesBulkData('campaign_id', localStorage.campaign_id);
                localStorage.removeItem("adgroup_id")
            }
        },
        getTopBoxesBulkData(key, id) {
            if (!this.selectedPlatform && !this.selectedReport) {
                this.$toast.error("Please select a platform and category.", {
                    position: "top-right",
                    timeout: 2000,
                    closeOnClick: true,
                    pauseOnFocusLoss: true,
                    pauseOnHover: true,
                    draggable: true,
                    draggablePercent: 0.6,
                    showCloseButtonOnHover: false,
                    hideProgressBar: true,
                    closeButton: "button",
                    icon: true,
                    rtl: false
                });
                return;
            }

            if (key === 'fsn_id' || key === 'adgroup_id') return;

            this.metricChartData = [];
            this.metricData = [];
            this.metricsList = [];
            this.dynamicLevelTableData = {};
            this.dailyTableData = null;
            this.level = "";

            this.loader = true;

            const url = "/api/flipkart/campaign_data"
            let payload = {
                aa_account_id: this.currentClient=='Demo Account'?this.selectedAccount:"IHWVB0GUU198",
                start_date: moment(this.dateRange.startDate).format("YYYY-MM-DD"),
                end_date: moment(this.dateRange.endDate).format("YYYY-MM-DD"),
                category: this.selectedReport.toLowerCase(),
                marketplace: this.selectedPlatform.toLowerCase()
            }

            if (key) {
                if(this.currentClient!= 'Demo Account'){
                    delete payload['aa_account_id']
                }
                localStorage.setItem(key, id);
                payload = {
                    ...payload,
                    [key]: id,
                }
                if (key === 'campaign_id') {
                    payload = { ...payload, level: 'adgroup' }
                }
                else if (key === 'adgroup_id') payload = { ...payload, level: 'fsn' }
            }


            axios.post(url, payload).then(res => {
                this.loader = false;

                // Initialize data variables
                this.metricData = res.data.data.total_data;
                this.dailyTableData = res.data.data.date_wise_table;

                if (res.data.data.graph_data) {
                    this.metricChartData = res.data.data.graph_data;

                    // Initialize Dropdown Keys
                    this.metricChartData.forEach(chartData => {
                        this.metricsList.push(chartData.key);
                    })

                    // Setting Dropdowns Default Values
                    this.selectedMetric1 = this.metricsList[0];
                    this.selectedMetric2 = this.metricsList[1];
                    this.processMetricChart();
                }
                if (res.data.data.campaign_data_table) {
                    this.dynamicLevelTableData = res.data.data.campaign_data_table;

                } else if (res.data.data.adgroup_data_table) {
                    this.dynamicLevelTableData = res.data.data.adgroup_data_table;
                }

                // Set level
                this.level = this.dynamicLevelTableData.level;
                this.selectedTab = this.level;

                this.processMetricSectionData(this.metricData);

                this.$nextTick(() => {
                    this.processTable("#dynamicLevelTable");
                    this.processTable("#dailyViewTable");
                })
            }).catch(err => {
                console.log(err);
                this.loader = false;
            })
        },

        processMetricChart() {
            this.processedMetricChart = {
                xAxis: [],
                seriesData: [
                    {
                        name: "",
                        data: [],
                    },
                    {
                        name: "",
                        data: [],
                        color: ""
                    },
                ]
            }

            let tempSeriesData = [
                {
                    name: this.selectedMetric1,
                    data: [],
                },
                {
                    name: this.selectedMetric2,
                    data: [],
                    color: "#FCB116",
                    yAxis: 1
                },
            ];
            let tempXAxis = [];

            this.metricChartData.forEach(chartData => {
                if (chartData.key === this.selectedMetric1) {
                    chartData.graph.forEach(seriesData => {
                        tempXAxis.push(seriesData.date);
                        tempSeriesData[0].data.push(seriesData.value)
                    })
                }
                if (chartData.key === this.selectedMetric2) {
                    chartData.graph.forEach(seriesData => {
                        tempSeriesData[1].data.push(seriesData.value)
                    })
                }
            });

            this.processedMetricChart.xAxis = tempXAxis;
            this.processedMetricChart.seriesData = tempSeriesData;
        },
        processMetricSectionData(data) {
            this.loader = true
            this.processedMetricData = []
            for (const boxData of data) {
                const tempMetricBoxData = {
                    key: '',
                    oldDateRange: '',
                    oldValue: '',
                    percentDifference: '',
                    prefix: '',
                    suffix: '',
                    value: '',
                    dataSource: '',
                    tooltip: '',
                    graphData: [],
                    target: ''
                };

                tempMetricBoxData.key = boxData.key;
                tempMetricBoxData.oldDateRange = boxData.old_date_range;
                tempMetricBoxData.oldValue = boxData.old_value;
                tempMetricBoxData.percentDifference = boxData.percentage_difference;
                tempMetricBoxData.prefix = boxData.prefix;
                tempMetricBoxData.suffix = boxData.suffix;
                tempMetricBoxData.value = boxData.value;
                tempMetricBoxData.dataSource = boxData.data_source ? boxData.data_source : null;
                tempMetricBoxData.tooltip = boxData.tooltip ? boxData.tooltip : boxData.view ? boxData.view : null;
                tempMetricBoxData.target = boxData.target ?? null

                if (boxData.graph) {
                    const tempGraphBoxData = {
                        xAxis: [],
                        ylabel: '',
                        seriesData: [
                            {
                                name: '',
                                data: [],
                                date: []
                            },
                            // {
                            //     name: '',
                            //     data: [],
                            //     color: "#A4C3BE",
                            //     zones: [
                            //         {
                            //             value: 0
                            //         },
                            //         {
                            //             dashStyle: 'Dash'
                            //         }
                            //     ],
                            //     date: []
                            // }
                        ],
                        prefix: '',
                        suffix: ''
                    };
                    tempGraphBoxData.seriesData[0].name = boxData.key;
                    // tempGraphBoxData.seriesData[1].name = 'Was ';
                    tempGraphBoxData.prefix = boxData.prefix;
                    tempGraphBoxData.suffix = boxData.suffix;

                    for (const graphData of boxData.graph) {
                        tempGraphBoxData.xAxis.push(moment(graphData.date).format("DD MMM"));
                        tempGraphBoxData.seriesData[0].data.push(graphData.value);
                        // tempGraphBoxData.seriesData[1].data.push(graphData.comp_value);
                        tempGraphBoxData.seriesData[0].date.push(moment(graphData.comp_date).format("DD MMM"));
                    }

                    tempMetricBoxData.graphData.push(tempGraphBoxData);
                }
                this.processedMetricData.push(tempMetricBoxData);
            }
            this.loader = false
        },

        processTable(id, sortCol = 0) {
            // $(id).DataTable().destroy();
            var search = require("@/assets/Icons_SVG/Search.svg");
            $(id).DataTable({
                searching: true,
                info: false,
                sorting: [[sortCol, "desc"]],
                initComplete: function (settings, json) {
                    $(id).wrap(
                        "<div style='overflow:auto; width:100%;position:relative;'></div>"
                    );
                },
                language: {
                    search: "",
                    searchPlaceholder: "Search...",
                    paginate: {
                        first: "",
                        previous: "<",
                        next: ">",
                        last: "",
                    },
                    lengthMenu: "<h5>Show entries</h5> _MENU_",
                },
            });
            $(id + "_filter > label").append(
                `<div class='search_icon'><img src='${search}' /></div>`
            );
        },

        //Generic Functions
        abbreviateNumber(value) {
            return new Intl.NumberFormat('en-US', {
                notation: "compact",
                maximumFractionDigits: 2
            }).format(value);
        },
        decodeHTML(html) {
            const txt = document.createElement('textarea');
            txt.innerHTML = html;
            return txt.value;
        },
        formatNumber(num) {
            if (isNaN(num)) {
                return num;
            }
            const num1 = Number(num).toFixed(2);
            const num2 = Number(num1).toLocaleString();
            return num2;
        },
    }
}