
import moment from "moment";

export default {
  name: "TypingLoader",
  props: {
    userMessage: String,
  },
  data() {
    return {
      date: new Date(),
      username: ""
    };
  },
  mounted() {
    const data = localStorage.getItem("userData");
    const parsedData = JSON.parse(data);
    this.username = parsedData?.data?.username
  },
  methods: {
    formatTimestamp(timestamp) {
      return moment(timestamp).format("DD MMM YYYY, hh:mm A");
    },
  },
};
