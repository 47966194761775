import axios from 'axios';
import lineChart from '@/components/charts/lineChart.vue';
import Loader from '@/components/Loader/Loader.vue';
import Inventory from './Inventory.vue';
export default {
    name: 'Summary',
    components: {
        Loader, lineChart
    },
    data() {
        return {
            loader: false,
            dateRange: {
                startDate: moment('01-19-2025').format("YYYY-MM-DD"),
                endDate: moment('01-25-2025').format("YYYY-MM-DD")
            },
            viewInventoryData: '',
            isExport:false,
            isUploadReport:false,
            isModalOpenforExcelUpload:'',
            salesASINsSheetData:'',
            inventorySheetData:'',

        }
    },

    created() {
        // this.initializeModal();
        this.getInventoryAnalysisData();
    },
    mounted() {
        this.initializeModal();
    },
    methods: {
        initializeModal() {
            const $excelUploadModal = document.getElementById("upload-excel-modal");
            const excelUploadModalOptions = {
                placement: "center",
                backdrop: "dynamic",
                backdropClasses: "bg-gray-900 bg-opacity-50 fixed inset-0 z-40",
                closable: false,
                onClose: () => {
                    //    this.shopifyStoreName=''
                },
            };
            this.isModalOpenforExcelUpload = new Modal($excelUploadModal, excelUploadModalOptions);
        },
        showUploadExcelModal (){
            this.isModalOpenforExcelUpload.show();
            this.$refs.inventoryInput.value=''
            this.$refs.salesInput.value=''
            this.salesASINsSheetData =''
            this.inventorySheetData=''
        },

        hideRuleModal(event){
            this.isModalOpenforExcelUpload.hide();
           
        },

        formatNumber(num) {
            if (isNaN(num)) { return num }
            let num1 = Number(num).toFixed(2);
            let num2 = Number(num1).toLocaleString();
            return num2
        },

        abbreviateNumber(value) {
            return Intl.NumberFormat('en-US', {
                notation: "compact",
                maximumFractionDigits: 2
            }).format(value);
        },

        uploadSalesASINsSheet(event){
            this.salesASINsSheetData = event.target.files[0]
        },

        uploadInventorySheet(event){
            this.inventorySheetData = event.target.files[0]
        },

        commonToast(type,message){
            this.$toast.error(message, {
                position: "top-center",
                timeout: 2000,
                closeOnClick: true,
                pauseOnFocusLoss: true,
                pauseOnHover: true,
                draggable: true,
                draggablePercent: 0.6,
                showCloseButtonOnHover: false,
                hideProgressBar: true,
                closeButton: "button",
                icon: true,
                rtl: false
            });     
        },

        uploadExcelSheet(){
            this.isUploadReport = true;
            if(!this.inventorySheetData){
                this.commonToast('error',"Please upload the inventory report")
                return false
            }
            if(!this.salesASINsSheetData){
                this.commonToast('error',"Please upload the sales report")
                return false
            }
            const url = "/api/v1/inventory_analytics/data_upload"
            let formData = new FormData()
            formData.append("inventory",this.inventorySheetData)
            formData.append("sales",this.salesASINsSheetData)
            formData.append("channel","amazon")
            formData.append("brand","godrej")
            axios.post(url,formData).then(res=>{
                this.$toast.success("Successfully uploaded", {
                    position: "top-center",
                    timeout: 2000,
                    closeOnClick: true,
                    pauseOnFocusLoss: true,
                    pauseOnHover: true,
                    draggable: true,
                    draggablePercent: 0.6,
                    showCloseButtonOnHover: false,
                    hideProgressBar: true,
                    closeButton: "button",
                    icon: true,
                    rtl: false
                });     
                this.isUploadReport = false;
                this.isModalOpenforExcelUpload.hide();

            }).catch(err=>{console.log(err)
                this.commonToast('eorror',"Technical Error. Please Try Again.")
                this.isUploadReport = false;
            });
        },

        exportInventoryTableData(){
        this.isExport = true
          const url = "/api/v1/inventory_analytics/analysis_report"
          const  payload={
            data: this.viewInventoryData.data
          }
          axios.post(url,payload).then(res=>{
            window.location = res.data.data
            this.isExport = false

          }).catch(err=>{
            this.isExport = false
            console.log("download",err)
          })
        },

        getInventoryAnalysisData() {
            this.viewInventoryData = ''
            this.loader = true
            const url = "/api/v1/inventory_analytics/analysis"
            axios.get(url).then(res => {
                this.viewInventoryData = res.data.data.data
                this.$nextTick(() => {
                    this.processTable('#reports_table',4)
                })
                this.loader = false


            }).catch((err) => {
                console.log("Err Plugin PlayAccountsData")
                this.loader = false
            })
        },

        processTable(id,sortCol=2) {
            $(id).DataTable().destroy();
            var search = require("@/assets/Icons_SVG/Search.svg");
            $(id).DataTable({
                searching: true,
                info: false,
                sorting: [[sortCol, "desc"]],
                initComplete: function (settings, json) {
                    $('.dataTables_scrollBody tfoot tr').css({ visibility: 'collapse' });
                    $('.dataTables_scrollBody thead tr').css({ visibility: 'collapse' });
                    $(id).wrap("<div style='overflow:auto; width:100%;position:relative;'></div>");
                },
                language: {
                    search: "", searchPlaceholder: "Search...", paginate: {
                        first: "",
                        previous: "<",
                        next: ">",
                        last: "",
                    },
                    lengthMenu: "<h5>Show entries</h5> _MENU_"
                },
            })
            $(id + "_filter > label").append(
                `<div class='search_icon'><img src='${search}' /></div>`
            );
            $(id + ' thead th:first-child').removeClass('sorting sorting_asc sorting_desc');
        },
    },
}