export default {
  name: "SuggestedPrompts",
  props: {
    handlePreDefinedPrompt: Function,
  },
  components: {},
  data() {
    return {
      preDefinedPrompts: [
        {
          id: 1,
          heading: "Top Performing",
          data: [
            {
              title: "Campaigns",
              description: "What are my top performing campaigns this week?"
            },
            {
              title: "Campaigns",
              description: "How have the total impressions changed over the last month?"
            }
          ]
        },
        {
          id: 2,
          heading: "Top Performing",
          data: [
            {
              title: "Campaigns",
              description: "Which campaign has generated the highest sales this week?"
            },
            {
              title: "Campaigns",
              description: "What is the highest average selling price of the first week of last month? Give the details with campaign name."
            }
          ]
        },

      ],
    };
  },
  computed: {},
  mounted() { },
  methods: {},
};
