import axios from "axios";
import Loader from "@/components/Loader/Loader.vue";
import { Modal } from "flowbite";
export default {
    name: "Clients",
    components: {
        Loader,
    },
    data() {
        return {
            loader: false,
            loader1: false,
            loader2: false,
            loader3: false,
            loader4: false,
            clientsList: [],
            searchKey: "",
            clientModal: null,
            previewModal: null,
            modalClientname: "",
            modalClientID: "",
            dataSource: {},
            dataSourceInput: {
                AdServer: "",
                ADWORD: "",
                name: "",
                GA: "",
                GAView: "",
                GAUTM: "",
                adwordUTM: "google / cpc",
                fbMedium: "",
                FB: "",
                FBPAGE: "",
                DBM: "",
                DBMVideo: false,
                Linkedin: "",
                Amazon: "",
                Magento: "",
                Amazon_Display: "",
                Flipkart_Display: "",
                Flipkart_Search: "",
                Twitter: "",
                yearStart: "",
                yearEnd: "",
                financialYear: "01-01",
                ecommerceCheck: false,
                programmaticCheck: false,
                pptReportCheck: false,
                GA4: {
                    id: ""
                }
            },
            pptReportInput: {
                selectedTitleFont: "",
                selectedBodyFont: "",
                pptTitle: "",
                titleColor: "#065559",
                fbReport: false,
                adwordReport: false,
                selectedLogo: "",
                selectedBackground: "",
                selectedCover: "",
                selectedLogoPreview: null,
                selectedBackgroundPreview: null,
                selectedCoverPreview: null,
                barColor1: "#065559",
                barColor2: "#FFAB00",
                groupedBarColor1: "#065559",
                groupedBarColor2: "#FFAB00",
                lineColor1: "#065559",
                lineColor2: "#40424D",
                lineColor3: "#F482AA",
                lineColor4: "#6956E5",
                lineColor5: "#FFAB00",
                tableColor: "#065559",
            },
            GA4: false,
            week: [
                "Sunday",
                "Monday",
                "Tuesday",
                "Wednessday",
                "Thursday",
                "Friday",
                "Saturday",
            ],

            fontsList: [],
            previewModalName: "",
            previewImgSrc: null,
        };
    },
    mounted() {
        this.initializeModal();
        this.getClients();
    },
    computed: {
        filteredClientsList() {
            return this.clientsList.filter((client) => {
                return client.name.toLowerCase().includes(this.searchKey.toLowerCase());
            });
        },
    },
    methods: {
        initializeModal() {
            //Client view modal
            const $targetClientModal = document.getElementById("client-modal");
            const clientModalOptions = {
                placement: "center",
                backdrop: "dynamic",
                backdropClasses: "bg-gray-900 bg-opacity-50 fixed inset-0 z-40",
                closable: true,
                onHide: () => {
                    this.modalClientname = "";
                    this.modalClientID = "";
                    this.dataSourceInput = {
                        AdServer: "",
                        ADWORD: "",
                        name: "",
                        GA: "",
                        GAView: "",
                        GAUTM: "",
                        adwordUTM: "google / cpc",
                        fbMedium: "",
                        FB: "",
                        FBPAGE: "",
                        DBM: "",
                        DBMVideo: false,
                        Linkedin: "",
                        Amazon: "",
                        Magento: "",
                        Amazon_Display: "",
                        Flipkart_Display: "",
                        Flipkart_Search: "",
                        Twitter: "",
                        yearStart: "",
                        yearEnd: "",
                        financialYear: "01-01",
                        ecommerceCheck: false,
                        programmaticCheck: false,
                        pptReportCheck: false,
                        GA4: {
                            id: ""
                        }
                    }
                    this.pptReportInput = {
                        selectedTitleFont: "",
                        selectedBodyFont: "",
                        pptTitle: "",
                        titleColor: "#065559",
                        fbReport: false,
                        adwordReport: false,
                        selectedLogo: "",
                        selectedBackground: "",
                        selectedCover: "",
                        selectedLogoPreview: null,
                        selectedBackgroundPreview: null,
                        selectedCoverPreview: null,
                        barColor1: "#065559",
                        barColor2: "#FFAB00",
                        groupedBarColor1: "#065559",
                        groupedBarColor2: "#FFAB00",
                        lineColor1: "#065559",
                        lineColor2: "#40424D",
                        lineColor3: "#F482AA",
                        lineColor4: "#6956E5",
                        lineColor5: "#FFAB00",
                        tableColor: "#065559",
                    }
                    this.GA4 = false
                }
            };

            //image preview pop-up
            const $targetPreviewModal = document.getElementById("preview-modal");
            const previewModalOptions = {
                placement: "center",
                backdrop: "dynamic",
                backdropClasses: "bg-gray-900 bg-opacity-50 fixed inset-0 z-40",
                closable: true,
                onHide: () => {
                    this.previewModalName = "";
                    this.previewImgSrc = null;
                },
            };

            //assigning modals
            this.clientModal = new Modal($targetClientModal, clientModalOptions);
            this.previewModal = new Modal($targetPreviewModal, previewModalOptions);
        },
        clearSearchBox() {
            this.searchKey = "";
        },
        toggleRadio() {
            this.dataSourceInput.GA = "";
            this.GA4 = !this.GA4;
        },
        async uploadImage(type, e) {
            let self = this;
            this.pptReportInput[`selected${type}`] = e.target.files[0];
            this.pptReportInput[`selected${type}Preview`] = URL.createObjectURL(this.pptReportInput[`selected${type}`]);

            const image = new Image();
            await new Promise((resolve) => {
                image.onload = () => {
                    const dimensions = {
                        height: image.height,
                        width: image.width,
                    };
                    resolve(dimensions);

                    if (dimensions.width < 90 && dimensions.height < 45) {
                        alert("Image dimensions should be equal or greater than (width: 90px, height: 45px)");
                        self.pptReportInput[`selected${type}`] = "";
                        self.pptReportInput[`selected${type}Preview`] = ""
                    }
                }
                image.src = this.pptReportInput[`selected${type}Preview`];
            });

        },
        showPreviewModal(imageType, imgSrc) {
            this.previewModalName = imageType + ' Image';
            this.previewImgSrc = imgSrc;
            this.previewModal.show();
        },
        mapClient(selectedClient) {
            this.getUserClients(selectedClient);
            this.getClients();
            this.getFontsList();
            this.modalClientname = "Register New Client";
            if (selectedClient !== "") {
                this.modalClientname = selectedClient.name;
                this.modalClientID = selectedClient.id;
                this.getKeyword(selectedClient.id);
                this.getAlerts(selectedClient.id);
            }
            this.$nextTick(() => {
                this.clientModal.show();
            });
        },
        getClients() {
            this.loader = true;
            const url = `/api/users/`;
            this.clientsList = [];

            axios.get(url).then((res) => {
                this.clientsList = res.data.clients;
                this.loader = false;
            }).catch((err) => {
                console.log(err);
                this.loader = false;
            });
        },
        getUserClients(selectedClient) {
            this.loader1 = true;
            const url = "/api/users/clients/";

            axios.get(url).then((res) => {
                this.dataSource = res.data.data;
                this.$nextTick(() => {
                    if (selectedClient !== '') this.getIndividualClient(selectedClient.id);
                })
                this.loader1 = false;
            }).catch((err) => {
                console.log(err);
                this.loader1 = false;
            });
        },
        getKeyword(clientID) {
            this.loader2 = true;
            const url = `/api/users/keyword/${clientID}/`;

            axios.get(url).then((res) => {
                console.log(res);
                this.loader2 = false;
            }).catch((err) => {
                console.log(err);
                this.loader2 = false;
            });
        },
        getAlerts(clientID) {
            this.loader3 = true;
            const url = `/api/users/alerts/${clientID}`;

            axios.get(url).then((res) => {
                console.log(res);
                this.loader3 = false;
            }).catch((err) => {
                console.log(err);
                this.loader3 = false;
            });
        },
        getIndividualClient(clientID) {
            this.loader4 = true;
            const url = `/api/users/clients/${clientID}/`;

            axios.get(url).then((res) => {
                this.dataSourceInput.adwordUTM = res.data.data.ADUTM
                this.dataSourceInput.name = res.data.data.name
                this.dataSourceInput.GAUTM = res.data.data.GAUTM
                this.dataSourceInput.fbMedium = res.data.data.FBUTM
                this.dataSourceInput.DBMVideo = res.data.data.dbm_video
                this.dataSourceInput.yearStart = res.data.data.week_start
                this.dataSourceInput.yearEnd = res.data.data.week_end
                this.dataSourceInput.financialYear = res.data.data.financial_year !== 'undefined' ? res.data.data.financial_year : "01-01"
                this.dataSourceInput.pptReportCheck = res.data.data.ppt_report
                this.dataSourceInput.ecommerceCheck = res.data.data.Ecomm
                this.dataSourceInput.programmaticCheck = res.data.data.prog

                if (res.data.data.GA4) {
                    this.GA4 = true;
                    this.dataSourceInput.GA4.id = res.data.data.GA4;
                }

                for (let clientSource of this.dataSource) {
                    if (clientSource.name === 'AdServer' && res.data.data.ADSERVER) {
                        for (let value of clientSource.values) {
                            if (value.id === res.data.data.ADSERVER) {
                                this.dataSourceInput.AdServer = value;
                            }
                        }
                    }
                    if (clientSource.name === 'ADWORD' && res.data.data.ADWORD) {
                        for (let value of clientSource.values) {
                            if (value.id === res.data.data.ADWORD) {
                                this.dataSourceInput.ADWORD = value;
                            }
                        }
                    }
                    if (clientSource.name === 'FB' && res.data.data.FB) {
                        for (let value of clientSource.values) {
                            if (value.name === res.data.data.FB) {
                                this.dataSourceInput.FB = value;
                            }
                        }
                    }
                    if (clientSource.name === 'FBPAGE' && res.data.data.FBPAGE) {
                        for (let value of clientSource.values) {
                            if (value.id === res.data.data.FBPAGE) {
                                this.dataSourceInput.FBPAGE = value;
                            }
                        }
                    }
                    if (clientSource.name === 'DBM' && res.data.data.DBM) {
                        for (let value of clientSource.values) {
                            if (value.id === res.data.data.DBM) {
                                this.dataSourceInput.DBM = value;
                            }
                        }
                    }
                    if (clientSource.name === 'Linkedin' && res.data.data.Linkedin) {
                        for (let value of clientSource.values) {
                            if (value.id === res.data.data.Linkedin) {
                                this.dataSourceInput.Linkedin = value;
                            }
                        }
                    }
                    if (clientSource.name === 'Amazon' && res.data.data.Amazon) {
                        for (let value of clientSource.values) {
                            if (value.id === res.data.data.Amazon) {
                                this.dataSourceInput.Amazon = value;
                            }
                        }
                    }
                    if (clientSource.name === 'Magento' && res.data.data.Magento) {
                        for (let value of clientSource.values) {
                            if (value.id === res.data.data.Magento) {
                                this.dataSourceInput.Magento = value;
                            }
                        }
                    }
                    if (clientSource.name === 'Amazon_Display' && res.data.data.Amazon_Display) {
                        for (let value of clientSource.values) {
                            if (value.id === res.data.data.Amazon_Display) {
                                this.dataSourceInput.Amazon_Display = value;
                            }
                        }
                    }
                    if (clientSource.name === 'Flipkart_Display' && res.data.data.Flipkart_Display) {
                        for (let value of clientSource.values) {
                            if (value.id === res.data.data.Flipkart_Display) {
                                this.dataSourceInput.Flipkart_Display = value;
                            }
                        }
                    }
                    if (clientSource.name === 'Flipkart_Search' && res.data.data.Flipkart_Search) {
                        for (let value of clientSource.values) {
                            if (value.id === res.data.data.Flipkart_Search) {
                                this.dataSourceInput.Flipkart_Search = value;
                            }
                        }
                    }
                    if (clientSource.name === 'Twitter' && res.data.data.Twitter) {
                        for (let value of clientSource.values) {
                            if (value.id === res.data.data.Twitter) {
                                this.dataSourceInput.Twitter = value;
                            }
                        }
                    }
                    if (!res.data.data.GA4 && res.data.data.GA) {
                        if (clientSource.name === 'GA') {
                            for (let value of clientSource.values) {
                                if (value.id === res.data.data.GA) {
                                    this.dataSourceInput.GA = value;
                                    for (let view of value.views) {
                                        if (view.id === res.data.data.GAVIEW) {
                                            this.dataSourceInput.GAView = view;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }


                this.loader4 = false;
            }).catch((err) => {
                console.log(err);
                this.loader4 = false;
            });
        },
        getFontsList() {
            this.loader = true;
            const url = "/api/report/fontlist";

            axios.get(url).then((res) => {
                console.log(res);
                if (res.data.data) this.fontsList = res.data.data;
                this.loader = false;
            }).catch((err) => {
                console.log(err);
                this.loader = false;
            });
        },
        updateClient() {
            this.loader = true;
            let url;
            if (this.modalClientname == "Register New Client") {
                url = "/api/users/clients/";
            } else {
                url = `/api/users/clients/${this.modalClientID}/`;
            }

            //Alert if nothing is selected before creating a new client
            if (!this.dataSourceInput.AdServer && !this.dataSourceInput.ADWORD && !this.dataSourceInput.GA && !this.dataSourceInput.FB && !this.dataSourceInput.Linkedin && !this.dataSourceInput.Amazon) {
                alert("Please select Adserver or Adword or GA or FB or Linkedin or Amazon");
                return;
            }

            //Create a form data
            const formData = new FormData();

            //Map client name conditionally
            if (this.dataSourceInput.name) {
                formData.append("name", this.dataSourceInput.name);
            } else if (this.dataSourceInput.ADWORD) {
                formData.append("name", this.dataSourceInput.ADWORD.name);
            } else if (this.dataSourceInput.GA) {
                formData.append("name", this.dataSourceInput.GA.name);
            } else if (this.dataSourceInput.AdServer) {
                formData.append("name", this.dataSourceInput.AdServer.name);
            } else if (this.dataSourceInput.FB) {
                formData.append("name", this.dataSourceInput.FB.name);
            } else if (this.dataSourceInput.Linkedin) {
                formData.append("name", this.dataSourceInput.Linkedin.name);
            } else if (this.dataSourceInput.Amazon) {
                formData.append("name", this.dataSourceInput.Amazon.name);
            };

            //Fixed Form Data fields
            formData.append("GAUTM", this.dataSourceInput.GAUTM);
            formData.append("Ecomm", this.dataSourceInput.ecommerceCheck);
            formData.append("ppt_report", this.dataSourceInput.pptReportCheck);
            formData.append("financial_year", this.dataSourceInput.financialYear);
            formData.append("week_start", this.dataSourceInput.yearStart);
            formData.append("week_end", this.dataSourceInput.yearEnd);
            formData.append("prog", this.dataSourceInput.programmaticCheck);
            formData.append("dbm_video", this.dataSourceInput.DBMVideo);
            formData.append("ADUTM", this.dataSourceInput.adwordUTM)

            //Conditional payloads/form data fields
            if (this.dataSourceInput.AdServer) formData.append("ADSERVER", this.dataSourceInput.AdServer.id);

            if (this.dataSourceInput.ADWORD) formData.append("ADWORD", this.dataSourceInput.ADWORD.id);

            if (this.GA4) {
                formData.append("GA4", this.dataSourceInput.GA4.id);
            } else {
                formData.append("GA", this.dataSourceInput.GA.id)
            }

            if (this.dataSourceInput.GAView) {
                formData.append("GAVIEW", this.dataSourceInput.GAView.id);
            };
            if (this.dataSourceInput.fbMedium) formData.append("FBUTM", this.dataSourceInput.fbMedium);
            if (this.dataSourceInput.FB) formData.append("FB", this.dataSourceInput.FB.name);
            if (this.dataSourceInput.FBPAGE) formData.append("FPAGE", this.dataSourceInput.FBPAGE.id);
            if (this.dataSourceInput.DBM) formData.append("DBM", this.dataSourceInput.DBM.id);
            if (this.dataSourceInput.Linkedin) formData.append("Linkedin", this.dataSourceInput.Linkedin.id);
            if (this.dataSourceInput.Amazon) formData.append("Amazon", this.dataSourceInput.Amazon.id);
            if (this.dataSourceInput.Magento) formData.append("Magento", this.dataSourceInput.Magento.id);
            if (this.dataSourceInput.Amazon_Display) formData.append("Amazon_Display", this.dataSourceInput.Amazon_Display.id);
            if (this.dataSourceInput.Flipkart_Display) formData.append("Flipkart_Display", this.dataSourceInput.Flipkart_Display.id);
            if (this.dataSourceInput.Flipkart_Search) formData.append("Flipkart_Search", this.dataSourceInput.Flipkart_Search.id);
            if (this.dataSourceInput.Twitter) formData.append("Twitter", this.dataSourceInput.Twitter.id);

            //payload for ppt report
            if (this.dataSourceInput.pptReportCheck) {
                if (this.pptReportInput.pptTitle) {
                    formData.append("ppt_title", this.pptReportInput.pptTitle)
                } else {
                    alert("Report Title Required.")
                    return;
                }

                if (!this.pptReportInput.fbReport && !this.pptReportInput.adwordReport) {
                    alert("Please select any report");
                    return;
                } else {
                    let reportList = [];
                    if (this.pptReportInput.fbReport) reportList.push("facebook")
                    if (this.pptReportInput.adwordReport) reportList.push("adword")
                    formData.append("ppt_list", reportList)
                };

                if (this.pptReportInput.selectedLogo) {
                    formData.append("ppt_logo", this.pptReportInput.selectedLogo);
                } else {
                    alert("Please upload logo image.");
                    return;
                }
                if (this.pptReportInput.selectedBackground) {
                    formData.append("background", this.pptReportInput.selectedBackground);
                } else {
                    alert("Please upload background image");
                    return;
                }
                if (this.pptReportInput.selectedCover) {
                    formData.append("cover", this.pptReportInput.selectedCover);
                } else {
                    alert("Please upload cover image");
                    return;
                }

                if (this.pptReportInput.selectedTitleFont) formData.append("slide_title_font", this.pptReportInput.selectedTitleFont);
                if (this.pptReportInput.selectedBodyFont) formData.append("body_font", this.pptReportInput.selectedBodyFont);

                formData.append("title_font_color", this.pptReportInput.titleColor);
                formData.append('bar_graph', [this.barColor1, this.barColor2]);
                formData.append('grouped_bar_graph', [this.groupedBarColor1, this.groupedBarColor2]);
                formData.append('line_graph', [this.lineColor1, this.lineColor2, this.lineColor3, this.lineColor4, this.lineColor5]);
                formData.append('table_color', [this.tableColor]);
            }

            axios.post(url, formData).then(res => {
                this.clientModal.hide();
                this.getClients();
                this.loader = false;
            }).catch(err => {
                console.log("err", err);
                this.loader = false;
            })
        },
    },
};