import axios from 'axios';
import Loader from '@/components/Loader/Loader.vue';
import dualAxisChart from '@/components/charts/dualAxisChart.vue'
import lineChart from '@/components/charts/lineChart.vue'
import { faL } from '@fortawesome/free-solid-svg-icons';
import html2canvas from 'html2canvas';

export default {
    name: "Ad Detials",
    components: {
        Loader,dualAxisChart,lineChart
    },
    data() {
        return {
            loader: false,
            savedCreatives: [],
            timelineGraphLoader:false,
            dateRange: {
                startDate: moment().subtract(6, 'days').format("YYYY-MM-DD"),
                endDate: moment().format("YYYY-MM-DD")
            },
            selectedMetrices:['ROAS','ACOS'],
            excludeList:['GA Purchase','Meta Purchases','Hook Rate','Hold Rate','Reach', 'CPC','CTR',],
            intervalsList: [
                {
                    key: 'day',
                    label: 'Day'
                },
                {
                    key: 'week',
                    label: 'Week'
                },
                {
                    key: 'month',
                    label: 'Month'
                },
                {
                    key: 'year',
                    label: 'Year'
                },
            ],
            selectedInterval: {
                key: 'day',
                label: 'Day'
            },
            selectedLineGraphMetric:'ROAS',
            selectedBarGraphMetric:'Purchase',
            metrices:['ROAS','Purchase'],
            timeLineGraphData:'',
            isMetricDropdown:false,
            checked:false,
            selectedMetric:'ACOS',
            selectedMetrices:['ACOS','ROAS'],
            metrices:['ROAS','Purchase','ACOS','Purchase1','ACOS1','Purchase2','ACOS2'],
            viewAllCampaignData:'',
            viewCreativeCardData:'',
            viewAllCreativeData:'',
            adId: this.$route.params.adId,
            viewAllCampaignData:'',
            viewMeteCreativeTimelineGraphData:'',
            tableHeader:'',
            tableData:'',
            isMetricDropdown:false,
            metrics : [], 
            graphDropdownMetric:[],
            lineGraphDropdownMetric:[],
            barGraphDropdownMetric:[],
            videoAnalysisGraphData:{},
            cardTableData:[
                {
                    key:'Spend',
                    label:'Amount Spend (₹)'
                },
                {
                    key:'GA Revenue',
                    label:'Revenue (₹)'
                },
                {
                    key:'ROAS',
                    label:'ROAS'
                },
                {
                    key:'CTR',
                    label:'CTR (%))'
                }
            ]
        }
    },
    created() {
        this.dateRange.startDate = this.$store.state.store.startDate
        this.dateRange.endDate = this.$store.state.store.endDate
        window.addEventListener("click", this.close)

    },
    watch:{
        metrics:{
            deep:true,
            handler(){
                this.selectMetric();
            }
        }
    },
    mounted() {
        // this.getSavedCreatives();
        this.dateRange.startDate = this.$store.state.store.filter.startDate
        this.dateRange.endDate = this.$store.state.store.filter.endDate
        this.getAllCreativeData()
        this.getAllTimelineData()
    },
    beforeDestroy() {
        window.removeEventListener("click", this.close)
    },
    methods: {
        close(event) {
            if (!this.$refs.metricDropdown.contains(event.target)) {
                this.isMetricDropdown = false
            }
        },
        formatDate(date){
            return moment(date).format("DD MMM YYYY");
        },
        selectMetric(){
            this.viewAllCampaignData = { ...this.tableData };  
            this.tableHeader = this.metrics.filter(data => data.isAvailable);
            this.$store.dispatch('store/setFBCreativeMetric',this.tableHeader)
        },

        removeSelectedMetric(idx){
            this.metrics[idx].isAvailable = false
        },
        applyFilter(){
            this.dateRange.startDate = moment(this.dateRange.startDate).format("YYYY-MM-DD")
            this.dateRange.endDate = moment(this.dateRange.endDate).format("YYYY-MM-DD")
            this.$store.dispatch('store/setDate',this.dateRange)
            this.getAllCreativeData();
            this.getAllTimelineData();
        },
        downloadCreative(){
            const self = this
            let id = "#card" + this.adId;
            let uniqueCardId = document.querySelector(id);
            let getScreenshotDiv = uniqueCardId.querySelector('.screenshot-btn');
            this.$nextTick(() => {
                getScreenshotDiv.style.visibility = "hidden"; // Hide button to avoid misalignment
                setTimeout(() => {
                    html2canvas(uniqueCardId, { useCORS: true, scale: 2}).then((canvas) => {
                        getScreenshotDiv.style.visibility = "visible"; // Restore after capture
                        canvas.style.display = "none";
                        document.body.appendChild(canvas);
                        const image = canvas.toDataURL("image/png").replace("image/png", "image/octet-stream");
                        const a = document.createElement("a");
                        a.setAttribute("download", `Weavr-${self.adId}.png`);
                        a.setAttribute("href", image);
                        a.click();
                    });
                }, 300); // Small delay ensures styles are applied
            });            
        },
        selectDeselectMetric(metric){
            console.log("check the metric",metric,this.checked)
        },
        setInterval(selectedInterval){
            this.selectedInterval= selectedInterval
            this.getAllTimelineData();
        },
        toggleAdMetricDropdown(){
            if(this.isMetricDropdown){
                this.isMetricDropdown = false
            }
            else{
                this.isMetricDropdown = true
            }
        },
        updateDate() {
            // if (this.appliedFilterMetrices.length) {
            //     this.filterCreativeData()
            // } else this.getCreativesData();
        },
        
        getAllCreativeData(){
            this.loader = true;
            this.viewAllCampaignData = [];
            this.viewAllCreativeData='';
            const url = "/api/v1/meta/creativedatanew?start_date="+this.dateRange.startDate+'&end_date='+this.dateRange.endDate+'&ad_id='+this.adId
            // const url = "/api/v1/meta/creativedata?start_date=2024-10-09&end_date=2024-10-15"
            axios.get(url).then(res => {
                this.loader = false;
                this.viewAllCampaignData = res.data;
                this.viewCreativeCardData = res.data.data[0]
                this.viewAllCreativeData= res.data,
                this.generateMetricsForFilter();
                this.processVideoGraphData();
            }).catch(err => {
                this.loader = false;
                console.log("Error in getting saved reports", err)
            })
        },
        generateMetricsForFilter(){
            let selectedMetrics= this.$store.state.store.selectedFBCreativeMetric.map(data=>data.key)
            this.metrics  = this.viewAllCampaignData.headers.map(data=>{
                return {
                    isAvailable : selectedMetrics.includes(data.key)?true:false,
                    label: data.value,
                    key:data.key
                }
            })
            this.tableHeader = selectedMetrics
            this.$store.dispatch('store/setFBCreativeMetric',this.tableHeader)
        },

        getAllTimelineData(){
            this.timelineGraphLoader = true;
            this.viewMeteCreativeTimelineGraphData = '';
            this.graphDropdownMetric=''
            const url = "/api/v1/meta/creativetimleine/graph"
            const payload = {
                start_date: this.dateRange.startDate,
                end_date: this.dateRange.endDate,
                interval: this.selectedInterval.key,
                ad_id: this.adId
            }
            axios.post(url,payload).then(res => {
                this.timelineGraphLoader = false;
                if(!res.data.data) return false
                this.viewMeteCreativeTimelineGraphData = res.data.data
                this.graphDropdownMetric = Object.keys(res.data.data[0]).filter((value) => value != "Timestamp");
                this.selectedLineGraphMetric = this.graphDropdownMetric[0]
                this.selectedBarGraphMetric = this.graphDropdownMetric[1]
                this.processCompDropdown();
                this.processGraphData();
            }).catch(err => {
                this.timelineGraphLoader = false;
                console.log("Error in getting saved reports", err)
            })
        },

        
        processCompDropdown(){
            this.barGraphDropdownMetric = this.graphDropdownMetric.filter((value) => value != this.selectedLineGraphMetric);
            this.lineGraphDropdownMetric = this.graphDropdownMetric.filter((value) => value != this.selectedBarGraphMetric);
        },
        processVideoGraphData(){
            this.videoAnalysisGraphData={
                xAxis:['Video plays at 25%','Video plays at 50%','Video plays at 75%','Video plays at 100%'],
                disableYLabel:true,
                seriesData:[{
                    name: 'Video Analysis',
                    data:[]
                    },
                ]
            }
            if(this.viewCreativeCardData){
                this.videoAnalysisGraphData.xAxis.forEach((data)=>{
                    console.log("check the dat",data)
                    this.videoAnalysisGraphData.seriesData[0].data.push(this.viewCreativeCardData[data]);
                })
            }
        },
        processGraphData(){
            this.processCompDropdown();
            this.timeLineGraphData={
                xAxis:[],
                disableYLabel:false,
                seriesData:[{
                    name: this.selectedLineGraphMetric,
                    data:[]
                    },
                    {
                        name: this.selectedBarGraphMetric,
                        data:[] 
                    }    
                ]
            }
            if(this.viewMeteCreativeTimelineGraphData){
                this.viewMeteCreativeTimelineGraphData.forEach((data)=>{
                    this.timeLineGraphData.xAxis.push(data.Timestamp);
                    this.timeLineGraphData.seriesData[0].data.push(data[this.selectedLineGraphMetric]);
                    this.timeLineGraphData.seriesData[1].data.push(data[this.selectedBarGraphMetric]);
                })
            }
        },
        
      

     
        abbreviateNumber(value){
			return Intl.NumberFormat('en-US', {
				notation: "compact",
				maximumFractionDigits: 2
			}).format(value);
        },

        formatNumber(num) {
            if (isNaN(num)) {return num}
            let num1 = Number(num).toFixed(2);
            let num2 = Number(num1).toLocaleString();
            return num2
        }
    }
}