import axios from 'axios'
import moment from 'moment'
import Loader from '@/components/Loader/Loader.vue'
import Header from '@/components/Header/Header.vue'
import lineChart from '@/components/charts/lineChart.vue'
import drillPieChart from '@/components/charts/drillPieChart.vue'


// api :-
// https://weavr.online/api/summary/campaigns/?start_date=2023-01-22&end_date=2023-02-21
// https://weavr.online/api/comment/
// https://weavr.online/api/summary/aggrigate/?start_date=2023-02-01&end_date=2023-02-21
// https://weavr.online/api/summary/aggrigate/?start_date=2023-01-11&end_date=2023-01-31

export default {
    name: 'Summary',
    components: {
        Header,
        Loader,
        lineChart,
        drillPieChart
    },
    data() {
        return {
            dateRange: {
                startDate: moment().subtract(1, 'week'),
                endDate: moment()
            },
            dataLoader: false,

            intervalsList: [
                {
                    key: 'day',
                    label: 'Day'
                },
                {
                    key: 'week',
                    label: 'Week'
                },
                {
                    key: 'month',
                    label: 'Month'
                },
            ],
            selectedInterval: {
                key: 'day',
                label: 'Day'
            },
            tabList: [
                { "key": "campaign", "value": "Campaign Summary" },
                { "key": "adgroup", "value": "Adword Summary" },
                { "key": "device", "value": "Device Summary" },
            ],
            selectedTab: 'campaign',
            viewAggrigateData: '',
            isMetricDropdown: false,
            processedTopBoxesData: '',
            metrics: [],
            filterBy: "",
            isFilter: false,
            selectedNumericCampaignValue: "",
            numericFiltersFields: [],
            selectedNumericMatricsValue: "",
            matricsInputValue: "",
            selectedStringCampaignValue: "",
            stringFiltersFields: [],
            selectedStringMatricsValue: "",
            StringMatricsInputValue: "",
            storeNumericFilterValue: [],
            storeStringFilterValue: [],
            graphView: false
        }
    },
    watch: {
        metrics: {
            deep: true,
            handler() {
                this.selectMetric();
            }
        }
    },
    // created(){
    //     window.addEventListener("click", this.close)
    // },
    mounted() {
        this.init();
    },

    // beforeDestroy() {
    //     window.removeEventListener("click", this.close)
    // },
    methods: {
        init() {
            this.formatDateRange();
            this.dataAPIs();
        },

        dataAPIs() {
            this.getAggrigateData();
        },

        formatDateRange() {
            this.dateRange.startDate = moment(this.dateRange.startDate).format('YYYY-MM-DD');
            this.dateRange.endDate = moment(this.dateRange.endDate).format('YYYY-MM-DD');
        },

        formatDates() {
            this.formatDateRange();
            this.dataAPIs()
        },

        toggleTab(selectedTab) {
            this.selectedTab = selectedTab
            this.formatDateRange();
            this.dataAPIs()
        },

        abbreviateNumber(value) {
            return Intl.NumberFormat('en-US', {
                notation: "compact",
                maximumFractionDigits: 2
            }).format(value);
        },

        setInterval(selectedInterval) {
            this.selectedInterval = selectedInterval;
            this.formatDateRange();
            this.getAggrigateData()
        },

        selectMetric(metric) {
            this.tableHeader = this.metrics.filter(data => data.isAvailable);
            this.$store.dispatch('store/setFBCreativeMetric', this.tableHeader)
            if ($.fn.DataTable.isDataTable('#reports_table')) {
                $('#reports_table').DataTable().destroy();
            }
            this.$nextTick(() => {
                this.processTable('#reports_table', 1);
            });

        },

        close(event) {
            if (this.metrics.length) {
                if (!this.$refs.metricDropdownRef.contains(event.target)) {
                    this.isMetricDropdown = false
                }
            }

        },

        toggleAdMetricDropdown() {
            if (this.isMetricDropdown) {
                this.isMetricDropdown = false
            }
            else {
                this.isMetricDropdown = true
            }
        },

        generateMetricsForFilter() {
            let selectedMetrics = ['impressions']
            this.metrics = this.viewAggrigateData.headers.map(data => {
                return {
                    isAvailable: selectedMetrics.includes(data.key) ? true : false,
                    label: data.label,
                    key: data.key
                }
            })
            this.tableHeader = selectedMetrics
        },

        decodeHTML(html) {
            let txt = document.createElement('textarea');
            txt.innerHTML = html;
            return txt.value; // decoder for HTML-encoded strings.
        },

        handleIsFilter() {
            const existingValue = this.storeNumericFilterValue.find(
                (item) => item[0].field === this.selectedNumericCampaignValue
            );

            if (existingValue) {
                this.selectedNumericMatricsValue = existingValue[0].operation;
                this.matricsInputValue = existingValue[0].value;
            }
            this.matricsInputValue = "";
            this.StringMatricsInputValue = "";
            this.isFilter = true;
        },

        handleFilterModal(data, storeFilterValue, selectedMatricsValueKey, matricsInputValueKey) {
            if (data.length <= 0) {
                return false;
            }

            let existingValue = storeFilterValue.find(
                (item) => item[0].field === data && item[0].operation === this[selectedMatricsValueKey]
            );

            if (existingValue) {
                this[matricsInputValueKey] = existingValue[0].value;
            } else {
                this[matricsInputValueKey] = "";
            }
        },

        handleNumericFilterModal(data) {
            this.handleFilterModal(
                data,
                this.storeNumericFilterValue,
                'selectedNumericMatricsValue',
                'matricsInputValue'
            );
        },

        handleNumericMatricFilterModal(data) {
            let existingValue = this.storeNumericFilterValue.find(
                (item) => item[0].field === this.selectedNumericCampaignValue && item[0].operation === data)
            if (existingValue) {
                this.matricsInputValue = existingValue[0].value;
            } else {
                this.matricsInputValue = ""
            }
        },

        clearFilter() {
            this.isFilter = false;
            this.matricsInputValue = "";
            this.StringMatricsInputValue = "";
        },


        handlePostFilters({
            selectedCampaignValue,
            selectedMatricsValue,
            matricsInputValue,
            storeFilterValue
        }) {
            let existingFilter = storeFilterValue.find(
                (item) => item[0].field === selectedCampaignValue && item[0].operation === selectedMatricsValue
            );

            if (existingFilter) {
                // existingFilter[0].operation = selectedMatricsValue;
                existingFilter[0].value = isNaN(matricsInputValue) ? matricsInputValue : parseFloat(matricsInputValue);
            } else {
                storeFilterValue.push([
                    {
                        operation: selectedMatricsValue,
                        field: selectedCampaignValue,
                        value: isNaN(matricsInputValue) ? matricsInputValue : parseFloat(matricsInputValue)
                    }
                ]);
            }
        },

        postNumericalFilters() {
            this.handlePostFilters({
                selectedCampaignValue: this.selectedNumericCampaignValue,
                selectedMatricsValue: this.selectedNumericMatricsValue,
                matricsInputValue: this.matricsInputValue,
                storeFilterValue: this.storeNumericFilterValue,
            });
            this.getAggrigateData()
            this.isFilter = false;
        },

        handleStringFilterModal(data) {
            this.handleFilterModal(
                data,
                this.storeStringFilterValue,
                'selectedStringMatricsValue',
                'StringMatricsInputValue'
            );

        },

        handleStringMatricsFilterModal(data) {
            let existingValue = this.storeStringFilterValue.find(
                (item) => item[0].field === this.selectedStringCampaignValue && item[0].operation === data)
            if (existingValue) {
                this.StringMatricsInputValue = existingValue[0].value;
            } else {
                this.StringMatricsInputValue = ""
            }
        },

        handlePostStringFilters() {
            this.storeStringFilterValue.push([
                {
                    operation: this.selectedStringMatricsValue,
                    field: this.selectedStringCampaignValue,
                    value: this.StringMatricsInputValue
                }
            ])
            this.getAggrigateData()
            this.isFilter = false;
        },

        RemoveAllBadgeValue() {
            this.storeNumericFilterValue = [];
            this.storeStringFilterValue = [];
            this.getAggrigateData()
        },

        RemoveBadgeValue(data) {
            const index = this.storeNumericFilterValue.flat().findIndex(
                (item) =>
                    item.field === data.field &&
                    item.operation === data.operation &&
                    item.value === data.value
            );

            if (index !== -1) {
                this.storeNumericFilterValue.splice(index, 1);
            }

            for (let i = 0; i < this.storeStringFilterValue.length; i++) {
                const itemArray = this.storeStringFilterValue[i];
                const matchIndex = itemArray.findIndex(
                    (item) =>
                        item.field === data.field &&
                        item.operation === data.operation &&
                        item.value === data.value
                );

                if (matchIndex !== -1) {
                    this.storeStringFilterValue.splice(i, 1);
                    break;
                }
            }
            this.getAggrigateData()
        },

        getAggrigateData() {
            let url = "/api/v2/summary/aggrigate/"
            const payload = {
                "start_date": this.dateRange.startDate,
                "end_date": this.dateRange.endDate,
                "interval": this.selectedInterval.key,
                "filter_type": this.selectedTab,
                "numeric_filters": this.storeNumericFilterValue.flat(),
                "string_filters": this.storeStringFilterValue.flat()
            }
            this.dataLoader = true;
            this.viewAggrigateData = ''
            this.processedTopBoxesData = ''
            axios.post(url, payload).then((res) => {
                this.viewAggrigateData = res.data
                this.numericFiltersFields = res.data.numarical_filters
                this.stringFiltersFields = res.data.string_filters
                this.selectedNumericCampaignValue = this.numericFiltersFields.fields[0]
                this.selectedNumericMatricsValue = this.numericFiltersFields.metrics[0]
                this.selectedStringCampaignValue = this.stringFiltersFields.fields[0]
                this.selectedStringMatricsValue = this.stringFiltersFields.metrics[0]
                // this.processedTopBoxesData = res.data.total
                this.processTopBoxData(res.data.total);
                this.$nextTick(() => {
                    this.processTable('#reports_table', 4)
                })
                this.generateMetricsForFilter();
                this.dataLoader = false
            }).catch((err) => {
                console.log(err);
                this.dataLoader = false;
            });
        },
        processTopBoxData(metricData) {
            let temp = metricData
            metricData.forEach(element => {
                let tempGraphBoxData = {
                    xAxis: [],
                    ylabel: '',
                    seriesData: [{
                        name: element.label,
                        data: []
                    }, {
                        name: 'Was ',
                        data: [],
                        color: "#A4C3BE",
                        zones: [{
                            value: 0
                        }, {
                            dashStyle: 'Dash'
                        }],
                        date: []
                    }],
                    prefix: element.prefix,
                    suffix: element.suffix
                }
                element.graph_data.forEach(graphData => {
                    tempGraphBoxData.xAxis.push(moment(graphData.date).format("DD MMM"))
                    tempGraphBoxData.seriesData[0].data.push(graphData.value)
                    tempGraphBoxData.seriesData[1].data.push(graphData.comp_value)
                    tempGraphBoxData.seriesData[1].date.push(moment(graphData.comp_date).format("DD MMM"))
                })
                delete element.graph_data
                element.graphData = tempGraphBoxData
            });
            this.processedTopBoxesData = temp
            console.log("check filnal output", temp)
        },

        processTable(id, sortCol = 2) {
            $(id).DataTable().destroy();
            var search = require("@/assets/Icons_SVG/Search.svg");
            $(id).DataTable({
                searching: true,
                responsive: true,
                info: false,
                scrollCollapse: true,
                fixedColumns: {
                    left: 1
                },
                // sorting: [[sortCol, "desc"]],
                initComplete: function (settings, json) {
                    $('.dataTables_scrollBody tfoot tr').css({ visibility: 'collapse' });
                    $('.dataTables_scrollBody thead tr').css({ visibility: 'collapse' });
                    $(id).wrap("<div style='overflow:auto; width:100%;position:relative;'></div>");
                },
                language: {
                    search: "", searchPlaceholder: "Search...", paginate: {
                        first: "",
                        previous: "<",
                        next: ">",
                        last: "",
                    },
                    lengthMenu: "<h5>Show entries</h5> _MENU_"
                },
            })
            $(id + "_filter > label").append(
                `<div class='search_icon'><img src='${search}' /></div>`
            );
            $(id + ' thead th:first-child').removeClass('sorting sorting_asc sorting_desc');
        },
    },

}