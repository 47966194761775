import axios from 'axios'
import moment from 'moment'
import Loader from '@/components/Loader/Loader.vue'
import Header from '@/components/Header/Header.vue'
import lineChart from '@/components/charts/lineChart.vue'
import pieChart from '@/components/charts/pieChart.vue'
import dualAxisChart from '@/components/charts/dualAxisChart.vue'
import heatMap from '@/components/charts/heatMap.vue'
import IndiaMap from '@/components/charts/IndiaMap/IndiaMap.vue'
import StateMapper from '@/components/charts/IndiaMap/IndiaState.json'
import { nextTick } from 'vue'
// api :-
// https://weavr.online/api/summary/campaigns/?start_date=2023-01-22&end_date=2023-02-21
// https://weavr.online/api/comment/
// https://weavr.online/api/summary/aggrigate/?start_date=2023-02-01&end_date=2023-02-21
// https://weavr.online/api/summary/aggrigate/?start_date=2023-01-11&end_date=2023-01-31

export default {
    name: 'Summary',
    components: {
        Header,
        Loader,
        lineChart,
        pieChart,
        dualAxisChart,
        heatMap,
        IndiaMap
    },
    data() {
        return {
            dateRange: {
                startDate: moment("2024-10-31").subtract(3, 'week'),
                endDate: moment("2024-10-31")
            },
            minDate: moment("2024-10-01"),
            maxDate: moment("2024-10-31"),
            dataLoader: false,
            // section wise loader variable
            metricLoader: false,
            columnChartLoader: false,
            summaryKeyDataLoader: false,
            pieChartLoader: false,
            stateLevelDataLoader: false,
            heatMapLoader: false,
            publicerDataLoader: false,
            campaignTypeList: [],
            selectedCampaingType: '',
            viewFilterData: [],
            viewDSPDropdownData: '',
            selectedDSP: '',
            viewSummaryKeyData: {},
            viewPieChartData: '',
            viewHeatMapData: '',
            viewPublisherData: '',
            viewGraphTabListData: '',
            selectedTab: 'Clicks',
            processedColumnChartData: '',
            processedHeatMapData: '',
            selectedHeatMap: 'Impressions',
            heatMapOptions: '',
            processedPieChartDropdownOptions: ['Clicks', 'Cost', 'Impressions'],
            selectedPieChartOption: 'impressions',
            selectedIndiaMapOption: 'impressions',
            indiaMapDropdowList: ['Clicks', 'Cost', 'Impressions'],
            processedPieChartData: [],
            viewStateLevelData: '',
            viewStateLevelTableData: '',
            processedStateLevelData: [],
            selectedStateDataType: 'graph'
        }
    },
    // created(){
    //     window.addEventListener("click", this.close)
    // },
    mounted() {
        this.init();
    },

    // beforeDestroy() {
    //     window.removeEventListener("click", this.close)
    // },
    methods: {
        init() {
            this.formatDateRange();
            this.getFilterData();
        },

        dataAPIs() {
            this.getTopBoxData();
            this.getPieChartData();
            this.getHeatmapData();
            this.getPublisherData();
            this.getIndiaMapData();
        },

        formatDateRange() {
            this.dateRange.startDate = moment(this.dateRange.startDate).format('YYYY-MM-DD');
            this.dateRange.endDate = moment(this.dateRange.endDate).format('YYYY-MM-DD');
        },

        formatDates() {
            this.formatDateRange();
            this.dataAPIs()
        },
        formatNumber(num) {
            if (isNaN(num)) return num
            let num1 = Number(num).toFixed(2);
            let num2 = Number(num1).toLocaleString();
            return num2
        },

        toggleColumnChart(selectedTab) {
            this.selectedTab = selectedTab;
            this.processColumnChart();
        },

        toggleHeatMap(selectedHeatMap) {
            this.selectedHeatMap = '';
            this.selectedHeatMap = selectedHeatMap;
            this.proecessedHeatMapData = ''
            this.processHeatMapData();
        },

        toggleStateLevelDataSection(selectedKey) {
            this.selectedStateDataType = selectedKey.toLowerCase();
            if (selectedKey == 'table') {
                if ($.fn.DataTable.isDataTable('#state_lavel_reports_table')) {
                    $('#state_lavel_reports_table').DataTable().destroy();
                }
                this.$nextTick(() => {
                    this.processTable("#state_lavel_reports_table");
                })
            }
            else {
                if ($.fn.DataTable.isDataTable('#state_lavel_reports_table')) {
                    $('#state_lavel_reports_table').DataTable().destroy();
                }
            }
        },
        abbreviateNumber(value) {
            return Intl.NumberFormat('en-US', {
                notation: "compact",
                maximumFractionDigits: 2
            }).format(value);
        },

        getFilterData() {
            let url = "/api/programmatic/filters"
            this.dataLoader = true;
            axios.post(url, {}).then((res) => {
                this.campaignTypeList = Object.keys(res.data?.data)
                this.campaignTypeList.unshift('All')
                this.selectedCampaingType = this.campaignTypeList[0]
                this.viewFilterData = res.data?.data
                this.viewDSPDropdownData = this.selectedCampaingType == 'All' ? ['All'] : this.viewFilterData[this.selectedCampaingType]
                this.selectedDSP = this.selectedCampaingType == 'All' ? 'All' : this.viewFilterData[this.selectedCampaingType][0]
                this.dataLoader = false
                this.dataAPIs();
            }).catch((err) => {
                console.log(err);
                this.dataLoader = false;
            });
        },

        changeCampaignType() {
            this.viewDSPDropdownData = ''
            this.viewDSPDropdownData = this.selectedCampaingType == 'All' ? ['All'] : ['All', ...this.viewFilterData[this.selectedCampaingType]]
            this.selectedDSP = this.selectedCampaingType == 'All' ? 'All' : this.viewDSPDropdownData[0]
            this.dataAPIs();
        },

        getTopBoxData() {
            this.viewSummaryKeyData = {}
            this.processedColumnChartData = ''
            this.summaryKeyDataLoader = true
            this.commonAPICall('summary').then(data => {
                this.viewSummaryKeyData = data
                if (data) {
                    this.viewGraphTabListData = Object.keys(data?.data[0]).filter((data) => data != 'timestamp')
                    this.$nextTick(() => {
                        this.processTable("#reports_table");
                    })
                    this.processColumnChart();
                }
                this.summaryKeyDataLoader = false
            }).catch((err) => {
                console.log("err", err)
                this.summaryKeyDataLoader = false
            });
        },

        getPieChartData() {
            this.viewPieChartData = ''
            this.pieChartLoader = true
            this.commonAPICall('piechart').then(data => {
                this.viewPieChartData = data
                this.processPieChart()
                this.pieChartLoader = false
            }).catch((err) => {
                console.log("err", err)
                this.pieChartLoader = false
            });
        },

        getHeatmapData() {
            this.viewHeatMapData = ''
            this.heatMapLoader = true
            this.commonAPICall('heatmap').then(data => {
                if (!data.data || Object.keys(data.data).length === 0) {
                    this.processedHeatMapData = {};
                    this.heatMapLoader = false;
                    return;
                }

                this.viewHeatMapData = data.data;
                this.heatMapOptions = Object.keys(this.viewHeatMapData[Object.keys(this.viewHeatMapData)[0]][0]).filter(key => key !== 'Time');
                this.processHeatMapData();
                this.heatMapLoader = false;
            }).catch((err) => {
                console.log("err", err)
                this.processedHeatMapData = {};
                this.heatMapLoader = false
            });
        },

        getPublisherData() {
            this.viewPublisherData = ''
            this.publicerDataLoader = true
            this.commonAPICall('publisher').then(res => {
                this.viewPublisherData = res
                this.$nextTick(() => {
                    this.processTable("#publisher_reports_table");
                })
                this.publicerDataLoader = false
            }).catch((err) => {
                this.publicerDataLoader = false
                console.log("err", err)
            });
        },

        getIndiaMapData() {
            this.viewStateLevelData = '',
                this.processedStateLevelData = []
            this.stateLevelDataLoader = true
            this.commonAPICall('map').then(res => {
                this.viewStateLevelData = res.data
                this.viewStateLevelTableData = res
                if (this.viewStateLevelData.length) {
                    this.processStateLevelData();
                }
                this.stateLevelDataLoader = false
            }).catch((err) => {
                this.stateLevelDataLoader = false
                console.log("err", err)

            });
        },

        async commonAPICall(keyType) {
            let url = "/api/programmatic/components"
            let payload = {
                "campaign": this.selectedCampaingType,
                "dsp": this.selectedDSP,
                "start_date": this.dateRange.startDate,
                "end_date": this.dateRange.endDate,
                "key": keyType    ///"heatmap"/"piechart"/"publisher"
            }
            // if(keyType=='heatmap'){ 
            //     payload.start_date= moment(this.dateRange.startDate).subtract(1, 'week').format('YYYY-MM-DD');
            // }
            return axios.post(url, payload).then((res) => {
                return res.data
            }).catch((err) => {
                return err;
                console.log(err);
            });
        },
        processStateLevelData() {
            this.processedStateLevelData = []
            for (let [key, stateName] of Object.entries(StateMapper)) {
                let matchingState = this.viewStateLevelData.find(stateData => stateData.city === stateName);
                if (matchingState) {
                    this.processedStateLevelData.push([key, matchingState[this.selectedIndiaMapOption.toLowerCase() + '_percentage'], '', matchingState[this.selectedIndiaMapOption.toLowerCase()]])
                }
                else {
                    this.processedStateLevelData.push([key, -5, "#f0f0f0"])
                }
            }
        },
        processPieChart() {
            this.processedPieChartData = []
            this.processedPieChartData = [{
                name: '',
                prefix: '',
                suffix: '',
                data: this.viewPieChartData.data.map((item) => { return ({ 'name': item.size, 'y': item[this.selectedPieChartOption.toLowerCase()] }) })
            }]
        },

        processHeatMapData() {
            if (!this.viewHeatMapData || Object.keys(this.viewHeatMapData).length === 0) {
                this.processedHeatMapData = {};
                return;
            }
            this.processedHeatMapData = {};

            let tempData = {}
            tempData.yAxis = Object.keys(this.viewHeatMapData);
            this.heatMapOptions.forEach((field) => {
                tempData = {
                    yAxis: [],
                    xAxis: [],
                    seriesData: [{
                        name: field,
                        borderWidth: 1,
                        borderColor: '#cdcdcd',
                        data: []
                    }],
                    dataLabels: {
                        enabled: true,
                        color: '#cdcdcd'
                    }
                }
                tempData.yAxis = Object.keys(this.viewHeatMapData);
                tempData.yAxis.reverse()
                this.viewHeatMapData[tempData.yAxis[0]].forEach(value => {
                    tempData.xAxis.push(value.Time)
                })
                tempData.xAxis.forEach((time) => {
                    tempData.yAxis.forEach((date, idx) => {
                        if (this.viewHeatMapData[date][time][this.selectedHeatMap]) {
                            tempData.seriesData[0].data.push([time, idx, this.viewHeatMapData[date][time][field]])
                        }
                        else {
                            tempData.seriesData[0].data.push([time, idx, null])
                        }
                    })
                })
                Object.assign(this.processedHeatMapData, { [field]: tempData });
            })

        },


        processColumnChart() {
            this.processedColumnChartData = ''
            let temp = {
                xAxis: [],
                disableYLabel: true,
                seriesData: [{
                    name: this.selectedTab,
                    data: [],

                }]
            }
            this.viewSummaryKeyData.data.forEach(data => {
                temp.xAxis.push(data.timestamp)
                temp.seriesData[0].data.push(data[this.selectedTab])
            });
            this.processedColumnChartData = temp;
        },

        processTable(id, sortCol = 2) {
            $(id).DataTable().destroy();
            var search = require("@/assets/Icons_SVG/Search.svg");
            $(id).DataTable({
                searching: true,
                responsive: true,
                info: false,
                scrollCollapse: true,
                fixedColumns: {
                    left: 1
                },
                // sorting: [[sortCol, "desc"]],
                initComplete: function (settings, json) {
                    $('.dataTables_scrollBody tfoot tr').css({ visibility: 'collapse' });
                    $('.dataTables_scrollBody thead tr').css({ visibility: 'collapse' });
                    $(id).wrap("<div style='overflow:auto; width:100%;position:relative;'></div>");
                },
                language: {
                    search: "", searchPlaceholder: "Search...", paginate: {
                        first: "",
                        previous: "<",
                        next: ">",
                        last: "",
                    },
                    lengthMenu: "<h5>Show entries</h5> _MENU_"
                },
            })
            $(id + "_filter > label").append(
                `<div class='search_icon'><img src='${search}' /></div>`
            );
            $(id + ' thead th:first-child').removeClass('sorting sorting_asc sorting_desc');
        },
    },

}